import React, { Component, Fragment } from 'react';
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
import ImageGallery from "../imageComponent/ImageGallery";
import InitFingerprintJS from "../features/IPLogging";
import withStyles from "@material-ui/core/styles/withStyles";
import CircleMenu from "../headerComponent/headerMobileDropDown";
import DesktopHorizontalNav from "../headerComponent/DesktopHorizontalNav";
import DesktopHorizontalNavBackup from "../headerComponent/Backup-Semi-Working-Popover-HorizonatlNav";
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SimpleAccordion from "../features/SimpleAccordion"

const styles = {
    homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
    infoContainer: {
        backgroundColor: 'rgba(255,255,255,.8)',
        borderRadius: '4px',
        flexGrow: 2,
        width: '100%',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        boxShadow: '2px 2px 3px #aaaaaa',
        maxWidth: '1480px'
        //marginBottom: '2%',
    },
    pageHeaderText: {
        fontWeight: '600',
        fontFamily: 'Work Sans, sans-serif',
        marginTop:'4%',
        marginBottom: '3%',
        textAlign: 'center',
        paddingBottom: '0.5%',
        textTransform: 'uppercase',
        textShadow: '1px 1px 2px #aaaaaa',
    },
    infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',
    },
    infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingBottom: '0.5rem',
    },
    infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    accordionComponent: {
    }
};

class EquipmentPage extends Component {
    //routeProps: ImagesProps;
    state = { modalIsOpen: false };
    done_retrieving = false;
    outside_render_images = undefined;



    render () {
        const equipment_data = [
            {name: 'Microphone', info: 'This is the best mic out there. Hands down.'},
            {name: 'Other Microphone', info: 'This is the sexiest mic out there. Doesnt work but looks cool'}

        ];
        const {classes} = this.props;
        // this.props has nothing to do with the image files/links
        return (
            <div>

                {window.screen.availWidth <= 738 ? (
                    <CircleMenu/>
                ) : <DesktopHorizontalNav/>}

                {window.screen.availWidth >= 738 ? (
                    <ThreeDimensionalLogo/>
                ) : null}


                <Typography className={classes.pageHeaderText} id={"pageHeaderText"} variant="h4" component="h5">
                    Equipment
                </Typography>
                <Container className={classes.infoContainer}>

                <p className={classes.infoSectionHeader} id={'infoSectionHeader'} >
                    Provided Studio Equipment
                </p>
                    <Divider className={classes.infoHeaderDivider}/>
                    <p className={classes.infoSectionBody} id={'infoSectionBody'}>
                        As is consistent with our instruments and facility as a whole, our equipment is not only pristine
                        but it is also of the utmost quality. At <i>The Den</i>, we only use professional equipment.
                        Using only tried and true staples, the equipment that you will use to record is of an industry-grade
                        level.
                    </p>
                    <Divider className={classes.insetInfoDivider} variant="inset"/>

                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
                        Below listed is a brief description of the equipment that we have on-site. If you would like to
                        bring your own equipment, or perhaps you have questions pertaining to our ours, feel free to
                        contact us using the <i>Contact Us</i> page.
                    </p>
                </Container>
                <SimpleAccordion className={classes.accordionComponent} props={equipment_data}/>

                <div id={'gallery-root'}>
                    <ImageGallery album_name={"Instruments_Prod"}/>
                    <InitFingerprintJS/>
                </div>

            </div>


        );
    }
}
export default withStyles(styles, { withTheme: false })(EquipmentPage);

//<ThreeDimensionalLogo/>
/**
 *  <div className={"container"} id={"equipment-header-container"}>
 <ThreeDimensionalLogo/>
 <div className={"container"} id={"equipment-body-container"}>
 <ImageGallery album_name={"Instruments"}/>
 </div>
 <InitFingerprintJS/>
 </div>
 */

//<FluidInstrumentsGallery className="carouselImageGallery"/>
//<img src="https://live.staticflickr.com/65535/50504608667_48b13e9044_b.jpg" width="745" height="1005" alt="n"/>
//<GoogleExample/>

