import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Grow from '@material-ui/core/Grow';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {

    MainZone: {
        //backgroundColor: '#F9F9F9',
        //backgroundImage: 'linear-gradient(to top, #e6b980 0%, #eacda3 50%)',
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: '#fff',
        borderRadius: '4px',
        //flexGrow: 2,  // Causing over-extension of background color on mobile version
        width: '90%',

},
    refinedGallery: {
        padding: '20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '15px',
        boxShadow: '1px 2px 0px #aaaaaa',
        //rowGap: '30px',
        //columnGap: '30px',
    },
    ImageComponent: {
        cursor:'pointer',
        opacity: '1',
        boxShadow: '2px 2px 3px #aaaaaa;',
    },
    GalleryImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    ModalGateway: {
    },
};




class RefinedGallery extends Component{

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            hasMounted: false,
            lightboxIsOpen: false,

        };
    }

    componentDidMount() {
        this.setState({hasMounted: true});
    }

    toggleLightbox = (selectedIndex) => {
        this.setState(state => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex: selectedIndex,
        }));
    };


    render() {
        const { classes } = this.props.classes;
        let formatted_images = [];
        console.log("Refined Gallery this.props: ", this.props);
        console.log("Refined Gallery State: ", this.state);
        console.log("Refined Gallery Classes: ", this.props.classes);
        console.log('UPDATE WITHIN MAIN');
        const Gallery = () => (
            <p>d</p>
        );



       /** const FullModal = () => (
            <ModalGateway >
                {lightboxIsOpen ? (
                    <Modal  onClose={this.toggleLightbox}>
                        <Carousel
                            currentIndex={selectedIndex}
                            views={formatted_images}
                        />
                    </Modal>
                ) : null}
            <h1>props.lightboxIsOpen</h1>
            </ModalGateway>

        ); **/

        return (
            <Fragment>
                <main className={this.props.classes.MainZone} id={'refined-gallery-main'}>
                    {this.state.hasMounted ? (
                        <Fragment>
                            <div>
                                {!this.props.images.isLoading ? (
                                    <ImagesC props={this.props} classes={this.props.classes}/>
                                ):<p>Images not loaded</p>}
                            </div>
                        </Fragment>
                    ):null}
                </main>



            </Fragment>

        );
    }
}



class ImagesC extends Component {
    constructor(props) {
        super(props);
        this.classes = this.props.classes; // For styling
        this.state = {
            isSorted: false,
            sortedImages: null,
            formattedImages: null,
            imageObjects: null,
        };
    }

    toggleLightbox = (selectedIndex) => {
        this.setState(state => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex: selectedIndex,
        }));
    };


    /**Some images have column property with value of 1, others with 2
     Sort images, arranging the images with a value of 1 first, putting those with a value of 2 at the end **/
    sortImagesBySize = () => {
        const images = this.props.props.images.images;
        let formatted_images = [];
        images.sort(function(a, b) {return parseFloat(a.cols) - parseFloat(b.cols);}); //
        images.map(((image, x) => (formatted_images[x] = {src: image.source.large_size.source})));
        this.setState(state => ({
            sortedImages: images,
            formattedImages: formatted_images,
            isSorted: true,
        }));
    };


    render () {
        if (!this.state.isSorted){(this.sortImagesBySize())}
        console.log('This.state.isSorted: ', this.state.isSorted);
        console.log('Render - Images Component, props: ', this.props);
        console.log('Render - Image Component, state: ', this.state);
        console.log('Render - this.classes: ', this.classes);
        console.log('Render - this.classes.refinedGallery: ', this.classes.refinedGallery);

        return (
                <div className={this.props.classes.refinedGallery} id={'refined-gallery'}>
                    {this.state.isSorted ? (
                        this.state.sortedImages.map((image, x) => (
                            <div className={this.classes.ImageComponent}
                                 onClick={() => {
                                     this.toggleLightbox(x);
                                     console.log('CLICK');
                                     console.log('Selected Index: ', this.state.selectedIndex);
                                     console.log("Lightbox State: ", this.state.lightboxIsOpen);
                                 }} // x represents index
                                 key={image.source.medium_size.source}
                            >
                                <Grow in={this.state.hasMounted}
                                      style={{ transformOrigin: '0 0 0' }}
                                      {...(this.state.hasMounted ? { timeout: 2000 } : {})}
                                >
                                    <img className={this.props.classes.GalleryImage}
                                         src={image.source.medium_size.source}
                                         alt={x.toString()}
                                         style={{
                                             width: '100%',
                                             height: '100%',
                                             objectFit: 'cover',
                                             transform: 'initial',
                                             visibility: 'visible',
                                             opacity: 1,
                                         }}
                                    />
                                </Grow>
                            </div>
                        ))):<p>Rendering Images</p>}

                    <ModalGateway >
                        {this.state.lightboxIsOpen ? (
                            <Modal  onClose={this.toggleLightbox}>
                                <Carousel
                                    currentIndex={this.state.selectedIndex}
                                    views={this.state.formattedImages}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>

        );
    }
}



export default withStyles(styles, { withTheme: false })(RefinedGallery, ImagesC);

//<div className={this.props.classes.refinedGallery} id={'refined-gallery-two'}>
//                     {this.state.isSorted ? (this.state.imageObjects.map((imageObj) => ({imageObj}))):<p>Working on it</p>}
//                 </div>



//                <FullModal className={classes.ModalGateway}/>
/**
 <div className={classes.refinedGallery}

 {...props}
 />
 **/
/**
const Image = (props) => (
    <div
        css={{
            backgroundColor: '#eee',
            ':hover': {
                opacity: 0.9,
            },
        }}
        {...props}
    />
);


/**
 const ClockGalleryAndModal = ({ source }) => (
 <img src={source} alt="Example" className="w-25 m-2" />
 );



 width: `calc(25% - ${gutter * 2}px)`,

 **/


/**
 {this.props.images.map(((image, x) => (

    <Image
    onClick={() => this.toggleLightbox(x)} // j represents index
    key={image.source.medium_size.source}
    >
    <img
    alt= {x.toString()}
    src={image.source.medium_size.source}
    //className={"w-25 m-2"}
    css={{
    cursor: 'pointer',
    position: 'absolute',
    maxWidth: '100%',
    }}
    />
    </Image>
    )))}
 **/