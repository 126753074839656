import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
// Already included! Does not need to be included here
//import './Assets/css/default.min.css';
import FooterText from "../TextComponents/FooterText";
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DenIconFileSvg from '../../Assets/photos/DenLogoPurple.svg'
import DenIcon from '../Icons/DenIcon'

const styles = {
    footerContainer: {
        margin: 'auto',
        height: '10%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    DenLogoSVGContainer : {
        position: 'relative',
        paddingLeft:'5%',
        order: '1',
        height: '300px',
        maxHeight: '320px',
        display: 'flex',
        flexDirection: 'column',
    },
    FooterTextContainer: {
        height: '100px',
        width: '100%',
        order: '2',
    },
    DenLogoSVG: {


    }

};




class Footer extends Component {

    render () {
        const {classes} = this.props;


        return (
            <Container className={classes.footerContainer} id={'footer-container'}>


                    <Container className={classes.DenLogoSVGContainer} id={'footer-client-logo-container'}>
                        <DenIcon classes={classes.DenLogoSVG} id={'footer-client-logo'}/>
                    </Container>

                    <Container className={classes.FooterTextContainer} id={'footer-happel-solutions'} >
                        <FooterText/>
                    </Container>



            </Container>


        );
    }
}


export default withStyles(styles, { withTheme: false })(Footer);
