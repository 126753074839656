import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Grow from '@material-ui/core/Grow';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
        },
        gridList: {
            width: 'inherit',
            height: 'inherit',
        },
        icon: {
        },
        image: {
            ':hover': {
                opacity: 0.9,
            }
        },
        gridtile: {
            padding: 2,
        },
};

class GalleryAndModal extends Component<*>{


    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            hasMounted: false,
            lightboxIsOpen: false,

        };
    }



    componentDidMount() {
        this.setState({hasMounted: true});
    }



    toggleLightbox = (selectedIndex: number) => {
        this.setState(state => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    };
    render() {
        const { classes } = this.props;


        let formatted_images = []; // for views
        console.log("GalleryAndModal this props: ", this.props);
        const { images } = this.props; // creates variable images by extracting it from this.props
        const { selectedIndex, lightboxIsOpen } = this.state;
        console.log("Selected index: ", selectedIndex);
        console.log("Images: ", images);
        images.sort(function(a, b) {
            return parseFloat(a.cols) - parseFloat(b.cols);
        });
        /*
        For some reason the below console.log shows that when mapping, image.source.original_size and thumbnail result
        in a single undefined image array of the 15 arrays within images

        --> Turns out that it's because the sizes_json array ends up being one element short on one of the images
                - original_size: json_images[11] is undefined when the last index of sizes_json is 10
                - this must have to do with the dimensions of one of the images being odd, therefore not allowing
                  one of the options to be present
                - need to change sizes_json[11] to sizes_json[(sizes.json).length - 1]
         */
        //this.props.images.map(((image, x) => (console.log("Mapping this.props.images: ", image.source.original_size))));
        this.props.images.map(((image, x) => (formatted_images[x] = {src: image.source.original_size.source}))); // set to formatted images
        //(console.log("Image: ", image.source.medium_size.source, " x: ", x))));
        console.log("formatted images: ", formatted_images);

        //<GridList cellHeight={160}
        return (
            <Fragment>
                <div className={"container"} id={"equipment-gallery-container"}>
                    <GridList cellHeight={390}
                              className={classes.gridList}
                              cols={Math.floor(this.props.images.length/5) + 1}
                              justify={"center"}
                              spacing={4}
                    >
                        {this.props.images.map(((image, x) => (

                            <GridListTile className={classes.gridtile} xs={2} key={x} cols={image.cols || 1} >
                                <Image className={classes.image}
                                    onClick={() => this.toggleLightbox(x)} // j represents index
                                    key={image.source.medium_size.source}
                                >
                                    <Grow in={this.state.hasMounted}
                                          style={{ transformOrigin: '0 0 0' }}
                                          {...(this.state.hasMounted ? { timeout: 2000 } : {})}
                                    >
                                    <img src={image.source.medium_size.source}
                                         alt={x.toString()}
                                         css={{
                                             cursor:'pointer'

                                         }}
                                    />
                                    </Grow>
                                </Image>
                            </GridListTile>
                        )))}
                    </GridList>
                </div>


                /* The onClick popup modal */
                <ModalGateway>
                    {lightboxIsOpen ? (
                        <Modal onClose={this.toggleLightbox}>
                            <Carousel
                                currentIndex={selectedIndex}
                                views={formatted_images}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: false })(GalleryAndModal);


const gutter = 2;

const Gallery = (props: any) => (
    <div
        css={{
            overflow: 'hidden',
            marginLeft: -gutter,
            marginRight: -gutter,
        }}
        {...props}
    />
);

const Image = (props: any) => (
    <div
        css={{
            backgroundColor: '#eee',
            ':hover': {
                opacity: 0.9,
            },
        }}
        {...props}
    />
);


/**
 const ClockGalleryAndModal = ({ source }) => (
 <img src={source} alt="Example" className="w-25 m-2" />
 );



 width: `calc(25% - ${gutter * 2}px)`,

 **/


/**
 {this.props.images.map(((image, x) => (

    <Image
    onClick={() => this.toggleLightbox(x)} // j represents index
    key={image.source.medium_size.source}
    >
    <img
    alt= {x.toString()}
    src={image.source.medium_size.source}
    //className={"w-25 m-2"}
    css={{
    cursor: 'pointer',
    position: 'absolute',
    maxWidth: '100%',
    }}
    />
    </Image>
    )))}
 **/