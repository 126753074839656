import React, { Component, Fragment } from 'react';


import Logo from '../../Assets/photos/DenLogoPurple.svg'

// Purple : https://svgshare.com/i/R2x.svg
// Grey: https://svgshare.com/i/R3g.svg

export default class EquipmentBanner extends Component<*> {
    //routeProps: ImagesProps;


    render() {

        return (
            <Fragment>
                <figure id={"fluidbox-3d"} data-fluidbox-loader>
                    <div className={"canvas"}>
                        <a href={Logo} className={"fluidbox__instance-22 fluidbox--initialized fluidbox--ready fluidbox--opened fluidbox--loaded"} id={"custom-trigger-1"}>
                            <div className={"fluidbox__wrap"} style={{zIndex: "990"}}>
                                <img src={Logo} alt={""} title={""} className={"fluidbox__thumb"} style={{opacity: "1"}}/>
                                <div className={"fluidbox__ghost"}
                                     style={{width: "320px",
                                         height: "320px",
                                         top: "0px",
                                         left: "0px",
                                         backgroundImage: `url(${Logo})`,
                                         opacity: "0",
                                         transform: "translate(0px, 0px) scale(1, 1)",
                                         backgroundRepeat: "no-repeat"}
                                     }>
                                </div>
                            </div>
                        </a>

                    </div>
                </figure>
            </Fragment>

        );
    }
}


//<FluidInstrumentsGallery className="carouselImageGallery"/>
//<img src="https://live.staticflickr.com/65535/50504608667_48b13e9044_b.jpg" width="745" height="1005" alt="n"/>
//<GoogleExample/>
/**
 *
 <div className={"fluidbox__ghost"} style={{width: "[w]", height: "[h]", top: "[t]", left: "[l]"}}>
 </div>
 * <div className={"fluidbox__ghost"}
 style={{width: "320px",
                                    height: "320px",
                                    top: "0px",
                                    left: "0px",
                                    backgroundImage: "url('http://i.imgur.com/ouqMP4F.jpg')",
                                    opacity: "0",
                                    transform: "translate(0px, 0px) scale(1, 1)"}}>
 </div>
 */

