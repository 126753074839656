

import React, { Component, createClass } from 'react';
import ReactDOM from 'react-dom';

// Already included! Does not need to be included here
//import './Assets/css/default.min.css';

import { Link } from "react-router-dom";

import logo from "./../../favicon.ico";

import ClearIcon from '@material-ui/icons/Clear';
import Icon from '@material-ui/core/Icon';
import SimplePopover from "../features/PopoverMenu";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

export default class DesktopHorizontalNav extends Component<> {

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    toggleMenu = (event) => {
        const current_target = event.currentTarget;

        this.setState(state => ({
            popover_open: !this.state.popover_open,
            open: !this.state.open,
            //anchorE1: this.state.anchorE1 ? null : current_target
            anchorEl: current_target
        }));
        console.log('Menu Click Event: ', event.currentTarget);
        console.log('Anchor E1: ', this.state.anchorE1);
    };

     handleClick = (event) => {
         this.setState(state => ({
             anchorE1: event.currentTarget
         }));
    };

    handleClose = () => {
        this.setState(state => ({
            anchorEl: null,
            popover_open: false
        }));
    };



    componentDidMount = () => {
        console.log('Mounted');
        this.setState(state => ({
            popover_open: false,
            open: false,
            anchorE1: null
        }));

    };

    render () {

        const linksArray = [
            { name: "home", url: "/", id: "1" },
            { name: "services", url: "/services", id: "2"},
            { name: "equipment", url: "/equipmentb", id: "3" },
            { name: "instruments", url: "/instruments", id: "4" },
            { name: "contact", url: "/contact-us", id: "5"}
        ];
        const socialArray = [
            {
                icon: "fa fa-github-square",
                url: "https://github.com/matthewvincent"
            },{
                icon: "fa fa-instagram",
                url: "https://www.instagram.com/middlestates/"
            },{
                icon: "fa fa-tumblr-square",
                url: "http://matthewvincentphotography.com/"
            }
        ];


        //const popover_open = Boolean(this.state.anchorEl);
        //const id = popover_open ? 'simple-popover' : undefined;
        console.log('anchor e1 on render from class: ', this.state.anchorEl);
        return (

            <header id={'new-header'}>
                <div className="container-fluid" id={'horizontal-nav-container'}>

                        <Button
                            toggle={this.toggleMenu}
                            popover_open={this.state.popover_open}
                            anchorE1={this.state.anchorEl}
                            onClose={this.handleClose}
                            open={this.state.open}
                            ariadescribedby={this.state.popover_open ? 'simple-popover' : undefined}
                            //onClick={this.toggleMenu}
                        > Sugma/Ligma Activator </Button>

                    <Panel
                        open={this.state.open}
                        links={linksArray}
                        socialLinks={socialArray}
                    />


                </div>
            </header>
        );
    }
}

export class Button extends Component {

    componentDidMount = () => {
        console.log('Mounted BUTT');


    };
    render () {
        console.log('Button Class Props: ', this.props);

        return (
            <div id={'popover-wrapped-component'}>

                <button
                    className={this.props.open
                        ? "menu-toggle close-button"
                        : "menu-toggle "}
                    onClick={this.props.toggle}
                    aria-describedby={this.props.popover_open ? 'simple-popover' : undefined}
                > <Icon className="fa fa-plus"><ClearIcon/></Icon>
                </button>
                <Popover
                    id={this.props.popover_open ? 'simple-popover' : undefined}
                    open={Boolean(this.props.anchorEl)}
                    anchorEl={this.props.anchorE1}
                    onClose={this.props.onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography>The content of the Popover.</Typography>
                </Popover>
            </div>
        );
    }
}


class Panel extends Component {
    render() {
        return (
            <div
                className={this.props.open
                    ? "menu-wrapper menu-open"
                    : "menu-wrapper"}
            >
                <Links
                    links={this.props.links}
                    open={this.props.open}
                />

            </div>
        );
    }
}

class Links extends Component {
    render() {
        const linkList = this.props.links.map((link) => (
            <li className="link" id={link.id}>
                <a href={link.url}>{link.name}</a>
            </li>
        ));

        return (
            <div
                className={this.props.open
                    ? "links-wrapper"
                    : "links-wrapper links-wrapper-closed"}
            > <ul className="link-list">
                {linkList}
            </ul>
            </div>
        );
    }
}

class Social extends Component {
    render() {
        const socialLinks = this.props.socialLinks.map((link) => (
            <a href={link.url}>
                <i className= {link.icon} />
            </a>
        ));

        return (
            <div
                className={this.props.open
                    ? "social-wrapper social-open"
                    : "social-wrapper social-closed"}
            >
            </div>
        );
    }
}

/**
 * <Social
 socialLinks={this.props.socialLinks}
 open={this.props.open}
 />
 */

//{socialLinks}

//ReactDOM.render(<Menu/>, document.getElementById('menu'));
//export default Menu;


/**

 <div className="logo">
 <img src={logo} alt="Logo"/>
 </div>

 **/