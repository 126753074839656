
import React, { Component } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SpeakerIcon from '@material-ui/icons/Speaker';
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup';
import TuneIcon from '@material-ui/icons/Tune';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import withStyles from "@material-ui/core/styles/withStyles";


// with useStyles = makeStyles   --> use     const classes = useStyles();
const styles = {
    mobileRoundMenuContainer: {
        position: 'fixed',
        zIndex: 1029,
        width: 'auto', // prevents overflow of the container onto the photo grid, making it unselectable
        marginTop: '3%', // positions menu button and menu itself slightly off the top of viewport
    },
    menuBurgerIconCircle: {
        color: 'white',
    },
};

class CircleMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,

        };
        this.toggle = () => {
            console.log('Menu State: ', this.state);
            this.setState({ open: !this.state.open });
        };
        this.links = [
            { name: "home", url: "/" },
            { name: "services", url: "/services" },
            { name: "equipment", url: "/equipmentb" },
            { name: "instruments", url: "/instruments" },
            { name: "contact", url: "/contact-us" }
        ];
    }

    getInitialState = () => {
        return { open: false };
    };



    render() {
        const {classes} = this.props;

        const linkList = this.links.map((link) => (
            <li className="link">
                <a href={link.url}>{link.name}</a>
            </li>
        ));

        const linksComponentList = this.links.map((link) => (
            <Link to={link.url} key={link.name} className="headerLink">
                {link.name}
            </Link>
        ));



        return (
            <Container id={'mobileRoundMenuContainer'} className={classes.mobileRoundMenuContainer}>
                <div id={'mobileRoundMenuDiv'}>
                    <div className={this.state.open ? "menu-wrapper menu-open" : "menu-wrapper"}>
                        <div className={this.state.open ? "links-wrapper" : "links-wrapper links-wrapper-closed"}>
                            <ul className="link-list"> {linkList} </ul>
                        </div>
                    </div>
                    <button className={this.state.open ? "menu-toggle close-button" : "menu-toggle "}
                            onClick={this.toggle}>
                        <MenuIcon className={classes.menuBurgerIconCircle}/>
                    </button>
                </div>

            </Container>
        );
    }
}


export default withStyles(styles, { withTheme: false })(CircleMenu);


/**<div className={this.props.open ? "social-wrapper social-open" : "social-wrapper social-closed"}>
 {socialLinks}
 </div>


 const socialLinks = this.props.socialLinks.map((link) => (
 <a href={link.url}>
 <i className= {link.icon} />
 </a>));


 const socialArray = [
 {
                icon: "fa fa-github-square",
                url: "https://github.com/matthewvincent"
            },{
                icon: "fa fa-instagram",
                url: "https://www.instagram.com/middlestates/"
            },{
                icon: "fa fa-tumblr-square",
                url: "http://matthewvincentphotography.com/"
            }
 ];

 **/