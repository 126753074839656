import React, {Component, Fragment} from "react";
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
//import BuildTable from '../features/BuildTable'
import axios from 'axios';
import data from "../../Data/data.json"
import {DataTable} from "react-data-components";
import VisitationTable from '../features/VisitationTable'
import ReactVirtualizedTable from "../features/PaginationTableExample";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";

const http = require("https");
const jsonp = require('jsonp');

const api_url = "extreme-ip-lookup.com/json/?callback=?";
const headers = {"Method": "POST", "Referrer-policy": "no-referrer",
                    "Accept": "application/json",
                    "Content-type": "application/json",
                    "mode": "no-cors"};


const h = {method: "POST", headers: { "Accept": "application/json, text/plain, */*",
"Accept-Encoding": "gzip, deflate, br",
"Accept-Language": "en-US,en;q=0.9",
"Cache-Control": "no-cache",
"Connection": "keep-alive",
"Content-Length": "0",
"DNT": "1",
"Host": "http://localhost:3000",
"Origin": "http://localhost:3000",
"Pragma": "no-cache",
"Referer": "http://localhost:3000/",
"Sec-Fetch-Dest": "empty",
"Sec-Fetch-Mode": "cors",
"Sec-Fetch-Site": "cross-site"}};

function getMoviesFromApiAsync() {
    return fetch('https://facebook.github.io/react-native/movies.json')
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson.movies;
        })
        .catch((error) => {
            console.error(error);
        });
}






async function sendIP(ip){

    const request = new XMLHttpRequest();
    request.open('POST', 'http://127.0.0.1:1337/yes', true);
    request.setRequestHeader('Content-type', 'application/json');
    //let login_information = await getUserDefinedInput(); // get i/o
    let request_body = JSON.stringify({piggie: "cow", bank: "oink", ip_addr: ip}); // stringify i/o

    request.onload = async function() {
        let server_response = await request.responseText;
        let server_response_object = JSON.stringify(server_response);

        if (request.status === 200 || request.status === 201) {
            console.log('Yeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeet');
            console.log(server_response_object.msg);
            //await createBrowserSession('sessionID', server_response_object.uuid, 1);
        } else if (request.status >= 202) {
            console.log('Error Code: '+ request.status);
            console.log('Message: ' +server_response_object.msg);
            //document.getElementById('invalid').style.display = 'block';
        }
    };

    await request.send(request_body);
}



async function sendIPAxios(ip, path, language, date){

    const response =
        await axios.get("http://127.0.0.1:1337/visit",  { params: {
                ip_addr: ip,
                path: path,
                language: language,
                date: date
            }});
    console.log(response.data)
}



async function getIPLogAxios(callback){


    async function sendRequest(cb) {
        const response = await axios.get("http://127.0.0.1:1337/getIPLog");
        cb(response.data);
    }

    await sendRequest((res) => {
        console.log("Done processing ip log");
        callback(res);
    })
}



async function getIPAddrData(cb) {
    fetch('https://ifconfig.me/all.json')
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            let res_data = data;
            let currentDateTime = new Date();
            let currentDateTimeStringFormatted =
                ("00" + (currentDateTime.getMonth() + 1)).slice(-2) + "/" +
                ("00" + currentDateTime.getDate()).slice(-2) + "/" +
                currentDateTime.getFullYear() + " " +
                ("00" + currentDateTime.getHours()).slice(-2) + ":" +
                ("00" + currentDateTime.getMinutes()).slice(-2) + ":" +
                ("00" + currentDateTime.getSeconds()).slice(-2);


            let lang = res_data.language.slice(0, 8);
            console.log("Lang: ", lang);
            res_data.language = lang;
            res_data.date = currentDateTimeStringFormatted;
            console.log("New Data: ", res_data);
            localStorage.setItem('ip_addr_data', JSON.stringify(res_data));
        }).then((data) => {cb(data)})
        .catch(error => console.error(error));
}









export default class Overview extends Component<props> {

    constructor(props) {
        super(props);
        this.state = {isTableDataLoaded: false, row_data: null};
        this.renderMapUrl = (val, row) =>
            <a href={`https://www.google.com/maps?q=${row['lat']},${row['long']}`}>
                Google Maps
            </a>;
        this.tableColumns = [
            { title: 'Name', prop: 'name' },
            { title: 'City', prop: 'city' },
            { title: 'Street address', prop: 'street' },
            { title: 'Phone', prop: 'phone', defaultContent: '<no phone>' },
            { title: 'Map', render: this.renderMapUrl, className: 'text-center' },
        ];

    }


    componentDidMount() {
        getIPAddrData( async () => {
            console.log("IP ADDR DATA: ", JSON.parse(localStorage.getItem('ip_addr_data')));
            const ip_addr_data = JSON.parse(localStorage.getItem('ip_addr_data'));
            console.log("Date String: ", );
            let current_path = window.location.pathname;
            //await sendIPAxios(ip_addr_data.ip_addr, current_path, ip_addr_data.language, ip_addr_data.date);
            //await sendIP(ip_addr);
            await getIPLogAxios( (row_data) => {
                this.setState({row_data: {row_data}});
                this.setState({isTableDataLoaded: true});
            });


        });

    };





        //console.log("ROWSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS: ", {data});


    render () {
        // this.props has nothing to do with the image files/links
        console.log('IsTableDataLoaded: ', this.state.isTableDataLoaded);
        return (
        <div className={"container"}>
            <Fragment>
                <ThreeDimensionalLogo/>
                <Typography variant="h2" component="h2" gutterBottom
                            style={{ textAlign: "center" }}>
                    Visitors </Typography>
                <div className={"container"} id={"overview-main-container"}>
                </div>
                {this.state.isTableDataLoaded ? (
                    //<VisitationTable data={data}/>
                    <ReactVirtualizedTable props={this.state.row_data}/>
                ):<p>Not done loading yet</p>}
            </Fragment>
        </div>
        );
    }
}

/**
 <DataTable
 className="container"
 keys="id"
 columns={this.tableColumns}
 initialData={this.state.rows.data}
 initialPageLength={5}
 initialSortBy={{ prop: 'city', order: 'descending' }}
 pageLengthOptions={[ 5, 20, 50 ]}
 /> **/

/**
 fetch('https://api.github.com/orgs/nodejs')
 .then(response => response.json())
 .then(data => {
            console.log(data) // Prints result from `response.json()` in getRequest
        })
 .catch(error => console.error(error));

 **/