
import React, { Component } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SpeakerIcon from '@material-ui/icons/Speaker';
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup';
import TuneIcon from '@material-ui/icons/Tune';
import EqualizerIcon from '@material-ui/icons/Equalizer';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    opaqueListHeader: {
        backgroundColor: '#22292554',
        display: 'inline',
        paddingRight: '100%',
        borderBottomWidth: 'thin',
        borderBottomColor: 'darkslategrey',
    },
    navContainer: {
        display: 'flex',
        paddingLeft: '0',
        overflow: 'hidden',

    },

    burgerContainer: {
        width: '25%',
        height: '25%',
        display: 'inline-flex',
        paddingLeft: '2%',
        paddingTop: '1%',
        paddingBottom: '2%',
    },
    menuBurger: {
        display: 'inline-flex',
        boxShadow: '1px 1px 2px #aaaaaa',
    },
    navTitle: {
        display: 'inline-flex',
        padding: '3% 1% 1% 1%',
        fontSize: '1.225rem',
        fontWeight: 'light',
        fontFamily: "helvetica neue, helvetica",
        fontStyle: 'normal',
        textAlign: 'center',
        textTransform: 'lowercase',


    },
    navLogo: {
        height: '6%',
        width: '6%',
        display: 'inline-flex',
        marginLeft: '10%',
        marginTop: '1%',
    },
    navListItemDivider: {
        display: 'inline-flex',
        width: '8%',
    },
    navTopDivider: {
        borderTopStyle: 'groove',
        padding: '3px',
    },
    navBottomDivider: {
        borderBottomStyle: 'groove',
        padding: '3px',
    },
});

export default function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button key={'Home'}>
                    <ListItemIcon>
                        <InboxIcon/>
                    </ListItemIcon>
                    <Link to="/" className="headerLink">Home</Link>
                </ListItem>
                <ListItem button key={'Services'}>
                    <ListItemIcon>
                        <EqualizerIcon/>
                    </ListItemIcon>
                    <Link to="/instruments" className="headerLink">Services</Link>
                </ListItem>
                <ListItem button key={'Equipment'}>
                    <ListItemIcon>
                        <SpeakerGroupIcon/>
                    </ListItemIcon>
                    <Link to="/equipment" className="headerLink">Equipment</Link>
                </ListItem>
                <ListItem button key={'Instruments'}>
                    <ListItemIcon>
                        <MusicNoteIcon/>
                    </ListItemIcon>
                    <Link to="/instruments" className="headerLink">Instruments</Link>
                </ListItem>

            </List>
            <Divider />
            <List>
                {['Contact Us', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
            <div id={'mobileNav'}>


            <Container fixed className={classes.navContainer}>
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Container className={classes.burgerContainer}>
                            <Button onClick={toggleDrawer(anchor, true)} className={classes.menuBurger} id={'mobileNavBurger'}><MenuIcon id={'menuBurgerIcon'}/></Button>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </Container>
                    </React.Fragment>
                ))}
                <ListItem className={classes.navListItemDivider}></ListItem>
                <Typography className={classes.navTitle} id={'navTitle'} variant="h3" component="h4">The Den Productions</Typography>
                <img className={classes.navLogo} src={'https://svgshare.com/i/R2x.svg'}></img>
            </Container>

            </div>
    );
}

//            <ListItem className={classes.navTopDivider} id={'navTopDivider'}> </ListItem>

//                <ListItem className={classes.navBottomDivider}></ListItem>
//<ListItem className={classes.opaqueListHeader}>

//</ListItem>

/*
class HeaderMobile extends Component {
    render () {


        return (

            <header id={'mobile-header'}>

                <div
                    className={clsx(classes.list, {
                        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                    })}
                    role="presentation"
                    onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}
                >
                    <List>
                        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                </div>


                <div className="logo">
                    <img src={logo} alt="Logo"/>
                </div>

                <nav>
                    <ul>
                        <li className="first">
                            <Link to="/" className="headerLink">Home</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to="/" className="headerLink">Services</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="last">
                            <Link to="/instruments" className="headerLink">Instruments</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to="/equipment" className="headerLink">Equipment</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="last">
                            <Link to="#" className="headerLink">Contact</Link>
                        </li>
                    </ul>

                </nav>

            </header>

        );
    }
}
*/

//export default HeaderMobile;
