import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Grow from '@material-ui/core/Grow';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import ScrollDemo from "../features/ScrollDemo";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
//xmlns:xlink={"http://www.w3.org/1999/xlink"}
//xml:space={"preserve"}

import headerImage from '../../../src/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '100%',
        marginLeft: '33%',
    },
    header: {
        display: 'flex',
        /**
         width: '100%',
         position: 'relative',
         backgroundColor: 'black',
         //width: '1920px',
         //height: '1080px',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         **/
    },
    containerForGrid: {
        width: '100%',
        height: '100%',
        padding: '0',
    },

    containerWithinContainer: {
        height: '100%',

    },
    img: {
        width: '100%',
        height: '100%',
        margin:'auto',
        justifyContent: 'left',

    },
    headerImageContainer: {
        height: '1',
        width: '1'
    },
    hiddenRef: {
        bottom: '-20em'
    },
    denText: {
        fontWeight: '200',
        fontSize: '2.5rem',
        fontFamily: 'Philosopher, sans-serif',
        fontStyle: 'italic',
        textAlign: 'center',
    },
    dropDownArrow: {
        width: '100%',
    },

    gridContainer: {

        //width: '33%',
        //marginLeft: '33%',
        //padding: '0 33% 30px 33%',
        //padding: '20% 33% 20%',
        //width: '33%', // affects arrow centering
        whiteSpace: 'nowrap',


    }

};

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#ffffff'}} fontSize={"large"} component={ExpandMoreIcon}/>
    )
}


// Local Storage -- homeHeaderPreviouslyShown
class ImprovedHeaderHome extends Component<*>{


    constructor(props) {
        super(props);
        this.state = {
            firstTimeRendering: true,
            selectedIndex: 0,
            hasMounted: false,
            lightboxIsOpen: false,
            displayHeadImage: true,
        };
        this.myRef = React.createRef();


        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflow = 'auto';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };

    }


    firstTimeRenderCheck = () => {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        // if: not, item headImageShown is equivalent to true
        //            => ('true' === 'true) => true => !true => false
        //            => ('false'/undefined === 'true') => false => !false => true
        //const shouldDisplay = !(localStorage.getItem('headImageShown') === 'true');
        //if (shouldDisplay) {
        //    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        //}
       // this.setState({displayHeadImage: shouldDisplay});
        //this.setState({firstTimeRendering: false});
    };


    // Happens after first render, first render will call firstTimeRenderCheck, which will get the value from LS and set
    // it as the state for displayHeadImage
    // and set firstTimeRender state to false
    // ComponentDidMount will take this state and
    componentDidMount() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        console.log('Improved Header Home -- Component Did Mount');
        console.log('Display Head Image: ', this.state.displayHeadImage);
        console.log('Okayy... But should be displaying? : ', !(localStorage.getItem('headImageShown') === 'true'));
        console.log('Body.style.overflow: ', document.getElementsByTagName('body')[0].style.overflow);
        //if (this.state.displayHeadImage) { document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        //} else { document.getElementsByTagName('body')[0].style.overflow = 'auto'}
    }



    render () {
        const { classes } = this.props;

        console.log('Local Storage, headImageShown: ', localStorage.getItem('headImageShown'));

        return (

            <div>
                {this.state.displayHeadImage ? (
                    <header id={'headimage'} className={classes.header} style={{show:true}}>
                        <Container className={classes.containerForGrid}>
                            <Container className={classes.containerWithinContainer}>

                                <Grid container className={classes.gridContainer}
                                      spacing={0}
                                      direction={"row"}
                                      alignItems={"center"}
                                      justify={"center"}
                                      style={{ minHeight: '75vh' }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.denText} variant="h3" component="h4">Enter the Den...</Typography>
                                    </Grid>


                                </Grid>
                                <Grid container className={classes.gridContainer}
                                      spacing={0}
                                      direction={"row"}
                                      alignItems={"center"}
                                      justify={"center"}
                                >
                                    <Grid item xs={12}>
                                        <Button id={'home-header-down-arrow'} className={classes.dropDownArrow} onClick={this.scrollToMyRef}> <DownArrow className={classes.icon}/> </Button>
                                    </Grid>
                                </Grid>

                                <div className={classes.hiddenRef}
                                     ref={this.myRef}
                                     style={{ marginTop: '25vh' }}

                                >.</div>
                            </Container>
                        </Container>
                    </header>
                ):null}
            </div>

        );
    }

    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}

export default withStyles(styles, { withTheme: false })(ImprovedHeaderHome);
