import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Grow from '@material-ui/core/Grow';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import ScrollDemo from "../features/ScrollDemo";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
//xmlns:xlink={"http://www.w3.org/1999/xlink"}
//xml:space={"preserve"}

import headerImage from '../../../src/studio.jpg'
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '100%',
        marginLeft: '33%',
    },
    header: {
        display: 'flex',
        /**
        width: '100%',
        position: 'relative',
        backgroundColor: 'black',
        //width: '1920px',
        //height: '1080px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
         **/
    },
    containerForGrid: {
        width: '100%',
        height: '100%',
        padding: '0',
    },

    containerWithinContainer: {
        height: '100%',

    },
    img: {
        width: '100%',
        height: '100%',
        margin:'auto',
        justifyContent: 'left',

    },
    headerImageContainer: {
        height: '1',
        width: '1'
    },
    hiddenRef: {
        bottom: '-20em'
    },
    denText: {
        fontWeight: '200',
        fontSize: '2.5rem',
        fontFamily: 'Philosopher, sans-serif',
        fontStyle: 'italic',
        textAlign: 'center',
    },
    dropDownArrow: {
        width: '100%',
    },

    gridContainer: {

        //width: '33%',
        //marginLeft: '33%',
        //padding: '0 33% 30px 33%',
        //padding: '20% 33% 20%',
        //width: '33%', // affects arrow centering
        whiteSpace: 'nowrap',


    }

};

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#ffffff'}} fontSize={"large"} component={ExpandMoreIcon}/>
    )
}


// Local Storage -- homeHeaderPreviouslyShown
class HeaderHome extends Component<*>{


    constructor(props) {
        super(props);
        this.state = {
            firstTimeRendering: true,
            selectedIndex: 0,
            hasMounted: false,
            lightboxIsOpen: false,
            displayHeadImage: false,
            doneCheckingLocalStorage: false,
        };
        this.myRef = React.createRef();
        this.updateDoneCheckingLS = () => {this.setState({doneCheckingLocalStorage: true})};

        this.updateHeadImageState = (shouldShow) => {
            if (shouldShow) {
                this.setState({displayHeadImage: true});
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            } else {
                this.setState({displayHeadImage: false});
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
            }
        };

        this.setHeaderStateFalse = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflow = 'auto';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };
        this.waitAgain = async () => { setTimeout( this.setHeaderState, 500) };
        this.waitSetState = async () => { await setTimeout(this.waitAgain, 500) };

    }
//<img alt={''} className={classes.img} width={1} src={headerImage}/>

    componentWillUnmount() {
        console.log('Unmoutning');
    }


    shouldRenderHeadImage = () => {
        if (localStorage.getItem('headImageShown') !== 'true') {
            console.log('Local Storage, item headImageShown = false');
            this.updateHeadImageState(true);
            this.updateDoneCheckingLS(); // Allow for the rendering of the object\
            this.setState({firstTimeRendering: false});  // Set has mounted
        }
    };



    render () {
        const { classes } = this.props;
        if (this.state.firstTimeRendering) { this.shouldRenderHeadImage()}
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        const displayHeadImage = this.state.displayHeadImage;

        return (

            <div>


                { this.state.doneCheckingLocalStorage ? (

                    <header id={'headimage'} className={classes.header} style={{show:{displayHeadImage}}}>
                    <Container className={classes.containerForGrid}>
                        <Container className={classes.containerWithinContainer}>

                                    <Grid container className={classes.gridContainer}
                                          spacing={0}
                                          direction={"row"}
                                          alignItems={"center"}
                                          justify={"center"}
                                          style={{ minHeight: '75vh' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.denText} variant="h3" component="h4">Enter the Den...</Typography>
                                        </Grid>


                                    </Grid>
                                    <Grid container className={classes.gridContainer}
                                      spacing={0}
                                      direction={"row"}
                                      alignItems={"center"}
                                      justify={"center"}
                                    >
                                        <Grid item xs={12}>
                                            <Button id={'home-header-down-arrow'} className={classes.dropDownArrow} onClick={this.scrollToMyRef}> <DownArrow className={classes.icon}/> </Button>
                                        </Grid>
                                    </Grid>

                                <div className={classes.hiddenRef}
                                     ref={this.myRef}
                                     style={{ marginTop: '25vh' }}

                                >.</div>
                        </Container>
                    </Container>
                </header>

                ):null}
            </div>

        );
    }

    scrollToMyRef =  async () => {
        this.setHeaderStateFalse();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
   };






        //this.componentWillUnmount();

        //setTimeout(function(){document.getElementById('headimage').setAttribute('display', 'none') }, 1000);

        //0, )

}

export default withStyles(styles, { withTheme: false })(HeaderHome);
//xport default withStyles(styles, { withTheme: false })(HeaderHome);



//                            <DownArrow className={classes.icon} onClick={() => { window.scrollTo(0, this.myRef.current.offsetTop)}} />
//                <Typography variant="h1" component="h2"> Enter the Den...</Typography>