import React, { Component } from 'react';
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
import ImageGallery from "../imageComponent/ImageGallery";
import InitFingerprintJS from "../features/IPLogging";
import PageTitle from "../TextComponents/PageTitle";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import {Paper} from '@material-ui/core'

import AccordionChildren from "../features/AccordionChildren";
import AccordionMasterParent from "../features/AccordionMasterParent";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
const BULLET = `\u2022 `;

const styles = {
    homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
    infoContainer: {
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: 'rgba(255,255,255,.95)',
        position: 'relative',
        borderRadius: '4px',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        boxShadow: '2px 55px 24px #aaaaaa',//'2px 2px 3px #aaaaaa',
        maxWidth: '1480px',
        marginBottom: '10rem',
        paddingTop: '2rem',
        marginTop: '2rem',
        paddingBottom: '4rem',
        //marginBottom: '2%',
    },
    pageHeaderText: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textAlign: 'center',
    },
    infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',

    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },
    infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    ListItemVariant : {
        justifyContent: 'center',
        paddingRight: '50%',
        marginLeft: '25%',
        wordSpacing: '0.15rem',
    },
    ListItemVariantLong : {
        justifyContent: 'center',
        paddingRight: '25%',
        marginLeft: '25%',
        wordSpacing: '0.15rem',
        //paddingRight: '25%'
    },
    ListHead : {

    },
    HeaderPaper: {
        marginTop: '1rem',
        display: 'flex',
        width: '100%',
    },
    HeaderPaperText: {
        paddingLeft: '0.25rem',

        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '0',
        marginTop: '0',
    },
    SubHeaderPaper: {
        marginTop: '1rem',
        display: 'inline-flex',
    },
    SubHeaderPaperText: {
       paddingLeft: '0.25rem',
       paddingRight: '0.25rem',
        fontSize: '1.0rem',
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        //marginLeft: '2.5rem',
        marginBottom: '0',
        marginTop: '0',
    },
    HeaderPaperBullet: {

        marginTop: '1rem',
        display: 'inline-flex',
        marginLeft: '2rem',
    },
    HeaderPaperBulletText: {
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        fontSize: '.5rem',
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        marginBottom: '0',
        marginTop: '0',
    },
    HeaderPaperBulletNote: {
        marginTop: '1rem',
        display: 'inline-flex',
    },
    HeaderPaperBulletNoteText: {
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        fontSize: '0.7rem',
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        //marginLeft: '2.5rem',
        marginBottom: '0',
        marginTop: '0',
    },

    BulletListItem: {
        paddingRight: 0,
        paddingLeft: 0,
    },

    accordionComponent: {
        maxWidth: '1480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '2px',

    },
    accordionHeader: {
        //backgroundColor: '#515D67',
        backgroundColor: '#eee',
        borderRadius: '2px',

    },

};


class ServicesPage extends Component {
    //routeProps: ImagesProps;
    state = { modalIsOpen: false };
    done_retrieving = false;
    outside_render_images = undefined;


    CreateAccordion = (props) => {
        console.log('Create Accordion Props: ', props);
        //            <AccordionElement {...props}/>
        const {classes} = this.props;
        console.log('Create Accordion Classes: ', classes);
        return (
            <Accordion className={classes.accordionComponent} id={'accordionComponent'}>
                <AccordionSummary className={classes.accordionHeader} expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                    Hello
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Typography>oo</Typography>
                </AccordionDetails>
            </Accordion>

        )
    };





    render () {
        const preventDefault = (event) => event.preventDefault();
        const {classes} = this.props;


        /** Audio **/
        const AudioAccordionData = [
            {title: 'Our Pricing', details: 'Pay per song, not per hour', icon_type:'none'},
            {title: 'Types of Recording',
                details: 'Our studio tries to accommodate all people who are in need of ' +
                    'a recording studio. From a single song to scheduled a podcast, if you envision it,' +
                    'we can make it work.', icon_type:'none'},
        ];

        const AudioAccordionChildren = <AccordionChildren items={AudioAccordionData} {...AudioAccordionData} />;



        /******************** PHOTOS *************************/
        const PhotoAccordionData = [
        ];

        const InHousePhotos = [
            {title: 'Headshots', details: "Do you need updated headshots for your portfolio? Contact us and we'd be" +
                    "happy to help."},
            {title: 'Photo Shoot', details: "Equipped with several backdrops, we offer photo shoots" +
                    " for a single person, as well as groups"},
        ];

        const TypesOfPhotoShoots = [
            {title: 'Headshots', details: "Do you need updated headshots for your portfolio? Contact us and we'd be" +
                    "happy to help."},
            {title: 'In-house Photo Shoot', details: "Equipped with several backdrops, we offer photo shoots" +
                    " for a single person, as well as groups"}
        ];

        const AtEventPhotos = [
            {title: 'Band Promos', details: "A photographer will accompany you to various locations, coordinating shots and photographing your band/group"},
            {title: 'Live Performance', details: "One of our photographers will meet you at your event and take photos throughout"},
            {title: 'Practice Session', details: "Whether it be at our studio or your usual practice spot, practice session photos always make for great photos"}
        ];

        const PhotoAccordionChildren = <AccordionChildren items={PhotoAccordionData} {...PhotoAccordionData} />;
        const PhotoAccordionAtStudio = <AccordionChildren name={'At Our Studio'} items={InHousePhotos} {...InHousePhotos}/>;
        const PhotoAccordionAtEvent = <AccordionChildren name={'At An Event'} items={AtEventPhotos} {...AtEventPhotos}/>;
        const PhotoMasters = [PhotoAccordionAtStudio, PhotoAccordionAtEvent];


        /******************** VIDEOS *************************/

        const VideoAccordionData = [
            {title: 'Cover Video Production', details: 'Working with you throughout the entire process to ensure that all ' +
                    'your requirements are met, we will film and edit a cover video for your band.', icon_type:'none'},
            {title: 'Studio Documentary',
                details: 'A film dedicated to covering the paths taken by members of your crew; outlining the history ' +
                    'endured: both the triumphs and the defeats.', icon_type:'none'},
            {title: 'Promotional Video',
                details: 'Video dedicated to the promotion of an event, cause, or perhaps just yourselves.', icon_type:'none'},
            {title: 'Music Video',
                details: 'Struggling to get a grasp on a creative perspective with regard to the angle in which you will' +
                    'take your music video. Let us help.', icon_type:'none'},
            {title: 'Practice Session Video',
                details: 'Similar to practice session photos, it always good idea to have snippets of video of your band practicing.' +
                    'It certainly makes for some of the best b-roll out there.', icon_type:'none'},
        ];


        const VideoAccordionChildren = <AccordionChildren items={VideoAccordionData} {...VideoAccordionData} />;


        /******************** Marketing *************************/

        const MarketingAccordionData = [
            {title: 'Album Artwork', details: 'Album artwork is of the utmost importance for a ' +
                    'band on the burgeoning cusp of success. In working with us you can be assured that final album ' +
                    'artwork will not only meet but exceed any and all expectations you.', icon_type:'none'},
            {title: 'Creation of Promo Material', details: 'Depending on your needs, our studio would be able to help' +
                    'you with creation of any of the following: Creation of social media banners, photos, editing images, creating videos, etc. ', icon_type:'none'},
            {title: 'Social Media Release Plan', details: 'Unlike twenty years ago when a cellphone was the size of a' +
                    'brick and music was discovered almost exclusively at via the radio and record shop,' +
                    'today a bands ability social media presence is debatably as important as the music itself.' +
                    'A band (any organization for that matter) must take their approach to social media marketing very' +
                    'seriously. If you do not feel like you are capable of embarking on that journey alone, feel ' +
                    'free to contact us and our consulting staff will leave you resting assured.', icon_type:'none'},
            {title: 'Professional Merchandising Connections', details: 'As is byproduct of the combined experience' +
                    'our employees have in the entertainment industry, at our disposal are several professional, industry-grade' +
                    'merchandising connections. The only stipulation being that in order to receive these benefits, this' +
                    'studio must have produced your single/EP.', icon_type:'none'},
            {title: 'Local Show Booking Connections', details: 'Again, as is a byproduct of countless years of professionally' +
                    'producing and performing, our studio has every connection you could want as an emerging band on Long Island.' +
                    ' Again, the only stipulation being that in order to receive these benefits, this ' +
                    'studio must have produced your single/EP.', icon_type:'none'},
        ];


        const MarketingAccordionChildren = <AccordionChildren items={MarketingAccordionData} {...MarketingAccordionData} />;




        const AudioServices = [
            {
                name: 'Audio Production', info: 'Pay per song, not per hour', icon_type: 'none'
            },
        ];

        //        const TestAccordion = this.CreateAccordion(AudioAccordionData);
        return (

            <div>


                <PageTitle title={{text: 'SERVICES'}}/>

                <Container className={classes.infoContainer} id={'DetailsToContainAnotherMaster'}>
                    <Typography className={classes.pageHeaderText} id={"pageHeaderServices"} variant="h4" component="h5">
                        OUR SERVICES
                    </Typography>

                    <Divider className={classes.infoHeaderDivider} id={'FirstServicesTableDivider'}/>
                    <AccordionMasterParent name={'Audio Production'}{...AudioAccordionChildren} />
                    <Divider className={classes.infoHeaderDivider} variant={"inset"} id={'ServicesInsetDivider'}/>
                    <AccordionMasterParent  name={'Photography'}{...PhotoAccordionChildren} masters={PhotoMasters} />
                    <Divider className={classes.infoHeaderDivider} variant={"inset"} id={'ServicesInsetDivider'}/>
                    <AccordionMasterParent name={'Videography'}{...VideoAccordionChildren} />
                    <Divider className={classes.infoHeaderDivider} variant={"inset"} id={'ServicesInsetDivider'}/>
                    <AccordionMasterParent name={'Marketing'}{...MarketingAccordionChildren} />



                </Container>
                <InitFingerprintJS/>


            </div>




        );
    }
}


export default withStyles(styles, { withTheme: false })(ServicesPage);

//<FluidInstrumentsGallery className="carouselImageGallery"/>
//<img src="https://live.staticflickr.com/65535/50504608667_48b13e9044_b.jpg" width="745" height="1005" alt="n"/>
//<GoogleExample/>

/** Old, and frankly ugly, page header
 * <Paper className={classes.HeaderPaper} id={'ServicesPaperTitle'}>
 <p className={classes.HeaderPaperText}>
 Our Services
 </p>
 </Paper>
 */

//                <AccordionMasterParent name={'Audio Production'}{...AudioAccordionChildren} />
//<AccordionMasterParent name={'Audio Production'}{...AudioAccordionChildren} />
/**
 * {TestAccordion}
 <ListItem className={classes.ListItemVariant}> (a) In-studio mastering</ListItem>
 <Divider variant="inset"/>
 <ListItem className={classes.ListItemVariantLong}>
 (b) We can put you in contact with the
 the Mastering Engineer used by The Deep End,
 but only if the song was recorded with us
 </ListItem>

 **/