import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
        },
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{ height: rowHeight}}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label, columnIndex }) => {
        const { headerHeight, columns, classes } = this.props;
        console.log("Label: ", label);

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"

                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

/**
const sample = [
    ['Frozen yoghurt', 159, 6.0, 24, 4.0],
    ['Ice cream sandwich', 237, 9.0, 37, 4.3],
    ['Eclair', 262, 16.0, 24, 6.0],
    ['Cupcake', 305, 3.7, 67, 4.3],
    ['Gingerbread', 356, 16.0, 49, 3.9],
];
 **/

function createData(ip, path, language, date) {
    {path === '/' ? (path = '/*homepage*') : path = path}
    return {ip, path, language, date };
}





const rows = [
    createData('68.132.70.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.70.134', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.124.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.190.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.12.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('72.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('69.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.23.69', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.254.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('92.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('101.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.21.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('30.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('48.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('96.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('25.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('25.132.198.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.23.198', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('25.132.23.198', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.122.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.101.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.869.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),

];


/**
for (let i = 0; i < 200; i += 1) {
    const randomSelection = sample[Math.floor(Math.random() * sample.length)];
    rows.push(createData(i, ...randomSelection));
}
 **/

export default function ReactVirtualizedTable(props) {
    console.log("Table Props: ", props.props.row_data);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rows, setRows] = useState(null);

    useEffect(() =>{


        if (!isLoaded) {
            const raw_data = props.props.row_data;
            const cleaned_rows = (raw_data.reverse()).map((row) => (createData(row.ip, row.path, row.language, row.date)));
            setRows(cleaned_rows);
            setIsLoaded(true);
            console.log("This is cleaned rows data: ", cleaned_rows);
            console.log("CreateData: ", createData('1.2.3.4', '/overview', 'en-US', 'today bitch'));
        }


    });

    return (
        <div className={"container"}>
            <Paper style={{ height: 400, width: '100%' }}>
                {rows ? (
                    <VirtualizedTable
                        rowCount={rows.length}
                        rowGetter={({ index }) => rows[index]}
                        columns={[
                            {
                                width: "278px",
                                label: 'IP Address',
                                dataKey: 'ip',
                            },
                            {
                                width: "277px%",
                                label: 'Path',
                                dataKey: 'path',
                                numeric: false,
                            },
                            {
                                width: "277px%",
                                label: 'Language',
                                dataKey: 'language',
                                numeric: false,
                            },
                            {
                                width: "555px",
                                label: 'Date',
                                dataKey: 'date',
                                numeric: false,
                            },
                        ]}
                    />
                ): <p>LOADING</p>}

            </Paper>
        </div>
    );
}