import React, { Component, Fragment } from 'react';

import GalleryAndModal from "./GalleryAndModal";
import RefinedGallery from "./RefinedGallery";
const Flickr = require('flickr-sdk');
//import ClockGalleryFlickrImplementor from "./ClockGalleryFlickrImplementor";
//const flickr_key = "7c9b3630d78042478d9946955360dd6f";


export default class ImageGallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {date: new Date(), isLoading: true, images: []};
        this.flickr_key = '7c9b3630d78042478d9946955360dd6f';
        this.user_id = "190684174@N02";
        this.FlickrAPI = new Flickr(this.flickr_key);
        this.setImageTotal = (image_total) => {
            this.setState({image_total: image_total});
        };
        this.setFlickrImages = (images) => {
            this.setState({images: images});
        };
        this.local_images = [{src: '../../../photos/examples/a.PNG'}, {src: '../../../photos/examples/b.PNG'},
            {src: '../../../photos/examples/c.PNG'}, {src: '../../../photos/examples/d.PNG'},
            {src: '../../../photos/examples/e.PNG'}, {src: '../../../photos/examples/f.PNG'},
            {src: '../../../photos/examples/g.PNG'}, {src: '../../../photos/examples/h.PNG'},
            {src: '../../../photos/examples/i.PNG'}, {src: '../../../photos/examples/j.PNG'},
            {src: '../../../photos/examples/k.PNG'}, {src: '../../../photos/examples/l.PNG'},
            {src: '../../../photos/examples/m.PNG'}, {src: '../../../photos/examples/n.PNG'},
            {src: '../../../photos/examples/o.PNG'}];
        this.getFlickrImages = (FlickrAPI, user_id, album_title, cb) => {
            let images_ = new Array();
            try {

                FlickrAPI.photosets.getList({user_id: user_id})
                    .then(function (res) {
                        let albums = res.body.photosets;
                        let total = albums.total;
                        return {albums: albums, total: total}

                    }).then(function (res) {
                    for (let i = 0; i <= res.total - 1; i++) {
                        if (res.albums.photoset[i].title._content === album_title) {
                            console.log("album_title does equal _content: ", res.albums.photoset[i]);
                            return res.albums.photoset[i];
                        }
                    }

                }).then(function (instruments_album_object) {
                    console.log("Instrument ALbum: ", instruments_album_object);
                    console.log('FLickr api type: ', typeof FlickrAPI);
                    return FlickrAPI.photosets.getPhotos({
                        photoset_id: instruments_album_object.id,
                        user_id: user_id
                    });
                }).then(function (instrument_images_object) {
                    console.log("Instrument Images OBject: ", instrument_images_object);
                    let instrument_images_object_json = (JSON.parse(instrument_images_object.text)).photoset.photo;
                    let image_total = instrument_images_object_json.length;
                    //setImageTotalFunction(image_total);
                    //console.log(instrument_images_object_json); console.log(image_total);
                    for (let r = 0; r <= image_total - 1; r++) {
                        FlickrAPI.photos.getSizes({photo_id: instrument_images_object_json[r].id}).then(function (sizes) {
                            let sizes_json = (JSON.parse(sizes.text)).sizes.size;
                            console.log("These are sizes: \n", sizes_json);
                            console.log("Length of sizes: ", (sizes_json).length);
                            let image = new Array();
                            if (sizes_json[(sizes_json).length - 1].width > sizes_json[(sizes_json).length - 1].height){
                                image.cols = 2;
                            } else {image.cols = 1};
                            image.caption = "";
                            image.author = {};
                            image.source = {
                                thumbnail: sizes_json[5],
                                medium_size: sizes_json[6],
                                large_size: sizes_json[9],
                                original_size: sizes_json[(sizes_json).length - 1],
                            };
                            console.log('Image before pushing: ', image);
                            images_.push(image);
                            if (images_.length === image_total) {
                                console.log("Done gathering images");
                                cb(images_);
                            }

                            console.log("Current Images: ", images_);
                        });

                    }
                    console.log("End");
                })

            } catch (error) {
                console.log('Error Occurred When Getting Photos: \n', error);
            }
        };

        this.flick_image_array = [];


    }

    componentDidMount() {
        // Pass along this.props.album_name
        this.getFlickrImages(this.FlickrAPI, this.user_id, this.props.album_name, (images) => {
            console.log("fucking finally: ", images);
            this.setState({images: images});
            this.setState({isLoading: false});

        });

        /**
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
         **/
    }

    /**
    componentWillUnmount() {
        clearInterval(this.timerID);
    }
     **/

    tick() {

        console.log('Current state of flick_album, on tick: ', this.state.images);
        console.log(this.local_images[0].src);
        this.setState({
            date: new Date()
        });
    }


    render() {
        console.log("ImageGallery: ", this.props.album_name);
        const { images, isLoading } = this.state;
        const { selectedIndex, lightboxIsOpen } = this.state;
        console.log('Image Gallery State: ', this.state);
        return (

            <Fragment>
                {!this.state.isLoading ? (
                        <RefinedGallery images={this.state}/>
                ): <p></p>}

            </Fragment>


            //{!isLoading ? ( {this.state.flick_image_array.map(({ caption, source }, j) => ())})};

        )
    }
}


/**
 return (
 <div>
 <h1>Hello, world!</h1>
 <h2>It is {this.state.date.toLocaleTimeString()}.</h2>
 <div>
 {this.local_images.map(image => (
                        <ClockGalleryComponent source={image.src} key={image.src}/>
                        ))}
 </div>
 <div>
 <ClockGalleryFlickrComponent/>
 </div>

 </div>
 );
 }
 }

 /**
 <div>
 {this.local_images.map(image => (
                            <ClockGalleryFlickrComponent source={image.src} key={image.src}/>
                    ))}
 </div>

 {this.state.images.map(image => ( <ClockGalleryComponent source={image.source.medium_size.source} key={image.source.medium_size.source}/>
                        ))}


 **/