import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

/**
 function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
 **/


function createData(ip_addr, language, date) {
    return {ip_addr, language, date };
}


function mapData(ip_data) {

}

const rows = [
    createData('68.132.70.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:34:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.70.134', "en-US,en;q=0.9", "Mon Oct 26 2020 01:35:38 GMT-0400 (Eastern Daylight Time)"),
    createData('68.132.23.124', "en-US,en;q=0.9", "Mon Oct 26 2020 01:36:38 GMT-0400 (Eastern Daylight Time)"),

];

export default function VisitationTable(props) {
    const classes = useStyles();
    console.log("Props w/in Visitation Table: ", props);
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Visitors</TableCell>
                        <TableCell align="right">Language&nbsp;  (g)</TableCell>
                        <TableCell align="right">User Agent&nbsp;(g)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.ip_addr}>
                            <TableCell component="th" scope="row">
                                {row.ip_addr}
                            </TableCell>
                            <TableCell align="right">{row.language}</TableCell>
                            <TableCell align="right">{row.user_agent}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/**
 *                         <TableCell align="right">IP Address</TableCell>

 <TableCell align="right">{row.ip_addr}</TableCell>








 **/