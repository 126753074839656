import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React, {Component} from "react";
import Container from "@material-ui/core/Container";
import FooterText from "../TextComponents/FooterText";
import FavoriteIcon from '@material-ui/icons/Favorite';
import SvgIcon from "@material-ui/core/SvgIcon";
import withStyles from "@material-ui/core/styles/withStyles";



//"M88.53,334.91a62.55,62.55,0,0,1,3.29-23.31c2.9-8.49,8.33-14.73,16.64-18.33a25.23,25.23,0,0,1,10.12-2.19h2.88c5.32-.27,9.84,1.62,13.89,4.94a96.26,96.26,0,0,1,15.58,15.87,52.78,52.78,0,0,1,6.45,11.22,11.55,11.55,0,0,1,1,3.79,38,38,0,0,1-.64,11.46c-1.55,6.4-5.08,11.36-11.1,14.23a38.86,38.86,0,0,1-16.29,4c-2.05,0-4.08.12-6.12.14a25.06,25.06,0,0,1-3.24-.11,6.75,6.75,0,0,1-6.09-4.82,18.15,18.15,0,0,1-1.25-7.4c.29-3.6,1.8-6.34,5.51-7.61a12.58,12.58,0,0,1,7.74-.48,21.25,21.25,0,0,0,5.33.53,8.88,8.88,0,0,0,4.76-1.46,4.83,4.83,0,0,0,2.47-5.49,13.29,13.29,0,0,0-1.52-4c-3-5.11-6.5-9.79-11.7-12.94-.82-.49-1.54-1.14-2.34-1.66a28.28,28.28,0,0,0-3.06-1.89c-4.5-2.26-8.24-1-10.38,3.6a33.22,33.22,0,0,0-3,10.7,60.84,60.84,0,0,0,.2,14.71c.73,4.87,1.47,9.73,2,14.61a42.23,42.23,0,0,1,.13,7.2c-.18,2.84-1.8,4.5-4.62,5.2-4.1,1-7.53.27-10.42-3a18.42,18.42,0,0,1-4.45-8.1,71.59,71.59,0,0,1-1.8-13.5C88.45,338.82,88.53,336.89,88.53,334.91Z";

function DenIconFunction(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 482 540">
            <path id="Imported Path"
                  fill="none" stroke="black" stroke-width="1"
                  d="M 207.03,419.54
           C 207.03,419.97 207.02,420.42 207.02,420.87
             207.02,426.12 207.40,431.29 208.12,436.34
             208.40,438.26 211.85,441.34 208.33,439.38
             214.18,447.26 211.85,447.26 211.85,447.26
             211.95,448.03 212.00,448.82 212.00,449.63
             212.00,449.79 211.99,449.96 211.99,450.12
             211.81,453.12 209.72,455.62 206.92,456.41
             205.75,456.76 204.50,456.94 203.21,456.94
             201.15,456.94 199.20,456.47 197.46,455.63
             195.44,454.82 193.93,453.02 193.51,450.84
             193.08,448.80 192.78,446.66 192.61,444.48
             192.13,437.53 191.76,430.61 191.61,423.67
             191.52,419.50 190.71,415.34 190.23,411.20
             189.88,408.35 189.47,405.51 189.09,402.66
             189.09,402.42 189.07,402.18 189.03,401.94
             188.82,397.26 190.83,394.83 195.56,394.19
             196.04,394.13 196.54,394.09 197.05,394.09
             201.57,394.09 205.49,396.65 207.44,400.40
             208.93,403.03 209.78,406.08 209.78,409.33
             209.78,409.57 209.77,409.81 209.76,410.05
             206.89,414.50 207.03,417.02 207.03,419.54 Z
           M 47.43,371.34
           C 46.81,368.96 45.08,367.04 42.82,366.16
             41.82,365.71 40.82,365.26 39.82,364.89
             28.06,360.48 21.66,351.63 19.44,339.62
             18.63,335.28 18.15,330.73 18.03,326.10
             17.88,321.08 17.10,316.22 15.77,311.60
             15.53,309.04 14.78,306.63 13.61,304.48
             12.77,302.70 12.13,300.78 11.71,298.79
             11.07,296.36 10.22,294.01 9.18,291.77
             8.91,290.77 8.01,290.04 6.93,290.04
             6.72,290.04 6.51,290.07 6.32,290.12
             6.32,290.12 6.32,290.12 6.32,290.12
             6.32,290.12 5.63,288.65 5.63,288.65
             5.63,288.65 5.63,288.65 5.63,288.65
             5.38,287.70 5.15,286.73 4.91,285.78
             4.91,285.78 4.91,285.78 4.91,285.78
             4.91,285.78 4.16,283.63 4.16,283.63
             4.16,283.63 4.16,283.63 4.16,283.63
             3.92,282.63 3.67,281.69 3.42,280.72
             1.20,272.99 0.01,264.74 0.01,256.22
             0.01,253.32 0.15,250.46 0.41,247.64
             1.09,240.02 2.35,232.58 4.14,225.34
             5.73,218.73 8.10,212.43 11.15,206.53
             14.77,199.63 18.59,192.84 22.27,185.99
             24.01,182.72 25.64,179.38 27.37,176.10
             28.02,174.82 28.75,173.59 29.55,172.40
             32.33,168.58 34.44,164.23 35.71,159.54
             37.40,152.89 38.99,146.24 40.78,139.67
             42.88,131.93 45.13,124.22 47.37,115.83
             44.95,117.44 42.60,118.23 40.17,118.23
             42.94,109.72 46.76,101.68 51.45,94.25
             53.86,90.40 56.31,86.57 58.80,82.74
             58.21,81.85 60.12,81.05 60.80,80.21
             60.80,80.21 62.28,78.04 62.28,78.04
             62.28,78.04 62.28,78.04 62.28,78.04
             63.62,77.22 64.65,75.93 65.11,74.39
             65.11,74.39 64.48,73.71 64.48,73.71
             64.48,73.71 87.14,54.96 87.14,54.96
             87.23,54.72 86.72,54.71 86.72,54.71
             90.55,51.12 94.63,47.78 98.92,44.71
             102.61,42.24 106.35,39.87 109.93,37.24
             116.70,32.24 124.09,28.25 131.52,24.37
             134.79,22.63 138.19,21.12 141.52,19.55
             149.49,15.80 157.92,12.72 166.66,10.38
             175.55,8.00 184.88,6.12 194.38,4.77
             204.55,3.13 215.16,2.28 225.97,2.28
             226.73,2.28 227.48,2.28 228.23,2.29
             228.23,2.29 229.30,2.29 229.30,2.29
             234.70,2.20 240.36,18.44 228.82,18.44
             228.36,16.92 228.11,15.31 228.11,13.63
             228.11,9.25 229.80,5.26 232.57,2.29
             237.44,11.35 237.79,15.89 233.63,15.89
             233.63,15.89 233.63,15.89 233.63,15.89
             233.63,15.89 238.63,16.60 238.63,16.60
             240.42,20.14 243.97,20.14 243.97,20.14
             246.58,20.51 249.15,21.03 251.65,21.71
             257.65,23.67 263.94,24.62 270.04,26.22
             271.19,24.89 272.15,23.38 272.85,21.73
             277.22,22.82 281.58,23.93 285.97,25.01
             287.98,28.95 290.83,26.06 290.83,26.06
             290.83,26.06 290.83,26.06 290.83,26.06
             290.83,26.06 293.72,26.06 293.72,26.06
             293.72,26.06 293.72,26.06 293.72,26.06
             294.56,25.57 295.13,24.65 295.13,23.60
             295.13,22.88 294.86,22.22 294.42,21.72
             296.53,24.86 295.59,26.47 296.01,26.47
             299.75,27.30 303.39,28.44 306.90,29.85
             309.06,33.05 309.57,34.65 308.43,34.65
             311.44,39.23 309.81,41.48 310.62,38.36
             311.95,45.73 313.41,49.43 314.79,41.27
             315.88,47.11 316.91,50.02 318.01,50.02
             318.88,50.23 319.69,50.60 320.41,51.08
             320.41,51.08 321.87,52.18 321.87,52.18
             321.87,52.18 326.87,57.18 326.87,57.18
             326.87,57.18 373.36,57.18 373.36,57.18
             373.36,57.18 400.16,57.18 400.16,57.18
             400.16,57.18 400.16,57.18 400.16,57.18
             396.16,56.91 392.07,58.17 388.08,56.87
             387.96,56.85 387.84,56.84 387.72,56.84
             387.60,56.84 387.48,56.85 387.36,56.86
             380.68,43.94 368.51,55.34 368.51,55.34
             367.71,55.17 366.88,55.08 366.02,55.07
             366.02,55.07 363.19,54.53 363.19,54.53
             356.28,53.11 349.36,51.60 342.41,50.29
             336.90,49.24 331.31,48.49 325.77,47.47
             314.08,45.45 301.87,44.17 289.45,43.68
             283.80,43.25 277.95,43.04 272.06,43.04
             266.21,43.04 260.41,43.25 254.67,43.67
             230.91,32.36 204.41,25.74 176.43,24.98
             162.33,28.66 148.91,34.07 136.40,40.93
             127.42,45.75 118.88,51.33 110.85,57.54
             100.66,65.32 91.46,74.33 83.47,84.35
             77.92,91.50 73.19,99.33 69.42,107.68
             66.38,114.17 63.67,120.97 61.33,127.93
             58.68,135.33 56.52,143.11 54.89,151.07
             53.40,159.47 51.16,167.62 48.29,175.46
             47.94,176.45 47.65,177.49 47.43,178.55
             45.63,182.37 43.84,186.17 42.08,189.97
             40.15,194.09 38.24,198.22 36.24,202.32
             35.32,204.42 34.20,206.43 32.93,208.32
             26.47,217.14 23.03,227.13 21.03,237.74
             19.84,243.89 19.22,250.34 19.22,256.93
             19.22,258.29 19.25,259.65 19.30,261.00
             19.63,272.20 22.62,282.73 27.66,291.98
             29.33,294.98 31.00,298.08 32.67,301.12
             33.91,303.38 34.81,305.87 35.29,308.50
             36.22,313.66 36.70,319.07 36.70,324.58
             36.70,324.59 36.70,324.61 36.70,324.62
             36.76,328.43 44.21,334.62 44.21,334.62
             44.37,335.82 44.66,336.99 45.05,338.10
             45.92,341.16 48.31,343.58 51.34,344.51
             53.47,347.71 52.35,349.30 52.89,349.30
             60.35,351.63 66.32,357.33 69.03,364.61
             70.15,367.56 71.06,370.66 71.73,373.85
             71.89,374.77 71.98,375.72 71.98,376.70
             71.98,377.33 71.94,377.95 71.87,378.56
             71.67,381.47 71.56,384.48 71.56,387.52
             71.56,391.89 71.78,396.21 72.20,400.47
             72.52,403.51 73.20,406.44 74.20,409.22
             77.29,417.56 77.13,426.12 76.21,434.79
             75.45,441.57 74.32,448.37 74.21,455.17
             74.10,465.75 76.91,475.52 84.48,483.52
             90.32,489.62 97.54,494.39 105.64,497.33
             113.64,500.38 122.16,502.52 131.01,503.62
             133.10,503.88 135.55,510.19 136.71,514.77
             153.47,514.77 135.55,514.77 135.55,514.77
             137.29,514.83 139.08,514.87 140.89,514.87
             146.85,514.87 152.74,514.53 158.54,513.89
             162.14,513.52 165.68,513.23 169.25,512.82
             175.75,511.95 182.07,510.48 188.14,508.47
             198.23,505.34 208.38,502.44 218.36,498.99
             231.88,494.40 244.73,488.34 256.75,481.03
             259.01,479.68 261.32,478.41 263.69,477.22
             268.03,475.13 272.11,472.57 275.88,469.63
             278.16,467.83 280.45,466.14 282.71,464.36
             285.52,462.15 287.68,459.15 288.86,455.69
             293.52,442.45 298.17,429.22 302.80,415.97
             304.31,411.67 305.64,407.32 307.24,403.05
             308.40,399.90 309.75,396.83 311.27,393.85
             312.76,390.91 314.09,387.83 315.23,384.66
             314.33,382.23 317.23,379.96 318.22,377.65
             321.01,371.13 324.88,365.21 328.59,359.20
             329.00,358.48 329.49,357.81 330.05,357.20
             335.52,351.63 340.40,345.51 346.19,340.29
             350.36,336.50 354.34,332.49 359.00,329.30
             360.95,327.93 362.98,326.66 365.09,325.50
             366.54,324.72 368.12,324.11 369.78,323.71
             370.44,323.54 371.14,323.45 371.86,323.45
             374.84,323.45 377.47,324.95 379.04,327.24
             379.73,328.12 380.14,329.23 380.14,330.43
             380.14,331.22 379.96,331.97 379.64,332.64
             378.57,334.95 377.16,337.07 375.48,338.93
             372.73,342.25 369.64,345.27 366.24,347.93
             362.24,350.92 358.56,354.44 354.74,357.71
             353.40,358.90 352.00,360.02 350.54,361.07
             345.95,364.31 342.29,368.80 340.05,374.04
             338.64,377.36 336.82,380.48 334.66,383.33
             332.57,386.06 330.91,389.14 329.76,392.45
             326.76,400.96 323.76,409.47 320.76,417.89
             319.24,422.20 317.67,426.46 316.23,430.78
             314.94,434.65 313.84,438.56 312.63,442.47
             310.48,449.41 307.49,455.98 303.78,462.08
             301.26,466.17 298.69,470.17 296.08,474.23
             293.29,478.43 289.85,482.18 285.90,485.31
             277.59,491.90 268.72,497.85 259.40,503.07
             243.73,512.07 226.88,517.92 209.85,523.43
             207.29,519.53 211.03,517.59 211.65,517.59
             207.48,519.14 203.06,520.25 198.47,520.88
             197.98,520.83 197.48,520.81 196.96,520.81
             196.45,520.81 195.95,520.83 195.45,520.88
             184.39,523.88 173.07,524.88 161.69,525.65
             153.49,510.33 153.32,502.66 161.19,502.66
             148.59,502.35 136.00,501.41 123.73,498.36
             114.55,501.97 104.50,503.95 93.99,503.95
             88.54,503.95 83.21,503.42 78.06,502.40
             71.06,497.06 65.82,490.22 61.19,482.93
             59.97,480.99 58.77,479.06 57.59,477.13
             57.11,475.78 56.69,474.44 56.17,473.06
             52.17,463.30 51.17,453.07 52.06,442.27
             46.94,443.40 41.82,443.90 36.69,443.90
             31.56,443.90 36.29,433.68 35.14,428.69
             35.14,428.49 35.14,428.29 35.14,428.08
             35.14,414.66 40.40,402.47 48.97,393.46
             48.97,393.46 48.97,378.70 48.97,378.70
             48.98,378.52 48.98,378.33 48.98,378.14
             48.98,375.70 48.43,373.40 47.43,371.34
             47.43,371.34 47.43,371.34 47.43,371.34 Z
           M 263.29,3.00
           C 263.29,3.00 268.29,3.71 268.29,3.71
             268.29,3.71 263.29,3.00 263.29,3.00 Z
           M 5.68,288.61
           C 6.88,289.61 6.37,290.08 6.37,290.08
             6.14,289.58 5.92,289.11 5.68,288.61 Z
           M 4.21,283.59
           C 5.68,285.77 6.36,285.77 6.36,285.77
             4.71,285.02 4.47,284.30 4.21,283.59 Z
           M 321.58,15.99
           C 321.58,15.99 319.41,15.27 319.41,15.27
             319.41,15.27 321.58,15.99 321.58,15.99 Z
           M 5.00,285.78
           C 5.24,287.23 6.20,287.95 7.88,287.95
             5.42,287.66 5.20,286.70 5.00,285.78 Z
           M 3.48,280.69
           C 3.73,281.69 3.97,283.37 6.39,283.37
             4.00,282.64 3.73,281.67 3.48,280.69 Z
           M 392.16,42.56
           C 390.42,42.43 388.88,41.61 387.80,40.38
             387.80,40.38 392.16,42.56 392.16,42.56 Z
           M 387.80,40.40
           C 385.53,40.12 383.44,39.50 382.01,37.52
             384.14,38.09 386.10,39.08 387.80,40.40
             387.80,40.40 387.80,40.40 387.80,40.40 Z
           M 462.68,139.63
           C 463.18,140.57 463.59,141.57 463.91,142.61
             466.27,153.29 468.10,164.06 467.58,175.00
             467.14,184.55 467.03,194.15 465.08,203.59
             464.26,207.59 463.87,211.64 463.23,215.65
             462.95,217.44 462.57,219.19 462.11,220.92
             460.74,226.01 459.40,231.09 457.92,236.15
             457.43,237.63 456.85,239.08 456.19,240.48
             456.48,238.43 452.21,235.91 452.21,235.91
             453.74,230.00 455.29,224.09 456.86,218.18
             457.30,216.68 457.53,215.09 457.53,213.44
             457.53,212.63 457.47,211.84 457.36,211.06
             457.29,210.54 457.24,210.00 457.24,209.45
             457.24,208.90 457.28,208.37 457.36,207.85
             456.92,203.82 450.94,209.35 447.78,208.85
             443.18,209.92 438.52,210.24 433.32,200.91
             417.63,177.93 438.52,177.93 438.52,177.93
             438.52,173.03 437.96,168.25 436.91,163.67
             463.33,144.63 463.06,142.10 462.68,139.63 Z
           M 263.29,3.00
           C 263.29,3.00 249.69,2.00 249.69,2.00
             248.89,1.88 248.05,1.81 247.20,1.81
             246.83,1.81 246.46,1.82 246.09,1.85
             239.96,-9.33 229.47,-9.33 229.47,-9.33
             229.47,-9.33 228.88,-9.33 228.88,-9.33
             228.22,-9.34 227.52,-9.34 226.83,-9.34
             216.09,-9.34 205.54,-8.50 195.26,-6.88
             185.82,-5.56 176.54,-3.68 167.46,-1.30
             158.70,1.01 150.24,4.11 142.17,7.89
             138.80,9.50 135.41,11.01 132.17,12.75
             124.78,16.67 117.39,20.60 110.58,25.62
             106.98,28.25 103.29,30.62 99.59,33.09
             95.32,36.15 91.27,39.49 87.45,43.09
             87.29,42.75 86.95,42.75 86.95,42.75
             86.95,42.75 75.13,52.64 75.13,52.64
             75.84,51.54 76.63,50.49 77.48,49.49
             79.79,47.06 82.12,44.63 84.59,42.39
             90.92,36.56 97.59,31.08 104.55,25.99
             132.47,10.55 164.61,1.72 198.81,1.57
             208.29,0.53 218.13,-0.00 228.10,-0.00
             228.26,-0.00 228.42,-0.00 228.58,-0.00
             229.20,-0.00 229.84,-0.01 230.48,-0.01
             240.26,-0.01 249.91,0.52 259.41,1.56
             260.86,4.61 263.96,4.61 263.96,4.61
             263.30,2.50 263.29,2.76 263.29,3.00 Z
           M 317.99,30.34
           C 317.99,30.34 317.99,31.09 317.99,31.09
             316.69,30.91 315.36,30.83 314.08,30.54
             298.64,26.88 282.44,24.04 265.97,22.05
             261.10,21.34 256.02,20.98 250.86,20.98
             250.51,20.98 250.16,20.98 249.81,20.98
             249.48,21.00 249.13,21.00 248.79,21.00
             247.92,21.00 247.06,20.96 246.21,20.88
             238.33,19.87 230.41,20.32 222.50,20.34
             220.12,20.34 217.36,12.55 216.11,7.71
             198.59,9.42 217.36,9.42 217.36,9.42
             208.30,10.48 199.49,12.36 191.00,14.98
             186.32,16.52 181.58,17.84 177.01,19.66
             165.26,24.37 154.07,30.21 143.56,37.00
             128.79,44.87 115.21,54.71 103.16,66.15
             96.03,72.38 89.52,79.30 83.75,86.82
             79.40,92.48 75.54,98.55 72.24,104.95
             67.99,113.24 64.37,122.00 61.45,131.07
             59.23,137.63 57.45,144.53 56.17,151.59
             54.79,159.54 52.58,167.20 49.64,174.49
             49.00,175.92 48.18,177.25 47.45,178.62
             47.65,177.56 47.92,176.51 48.26,175.50
             51.15,167.85 53.39,159.76 54.91,151.43
             56.51,143.45 58.66,135.66 61.31,128.11
             63.62,121.15 66.34,114.36 69.40,107.75
             73.15,99.38 77.88,91.54 83.44,84.36
             91.42,74.33 100.62,65.30 110.82,57.51
             118.83,51.30 127.37,45.72 136.35,40.85
             148.77,34.02 162.16,28.63 176.24,24.92
             186.22,22.39 196.71,20.66 207.45,19.79
             214.75,19.09 222.33,18.72 229.99,18.72
             233.79,18.72 237.58,18.81 241.34,18.99
             253.69,19.47 265.85,20.74 277.76,22.75
             283.30,23.79 288.89,24.59 294.42,25.61
             301.35,26.92 308.28,28.43 315.20,29.85
             315.20,29.85 317.99,30.34 317.99,30.34 Z
           M 382.05,37.53
           C 381.05,37.38 379.86,37.43 378.94,41.02
             379.94,42.91 380.18,44.86 381.35,46.78
             384.95,46.78 380.18,46.78 380.18,46.78
             380.83,47.10 381.44,47.49 381.98,47.95
             384.17,48.87 386.59,49.38 389.13,49.38
             390.49,49.38 391.81,49.24 393.08,48.97
             393.99,49.48 394.62,50.46 394.62,51.58
             394.62,51.61 394.62,51.63 394.62,51.66
             395.52,51.66 396.28,51.66 397.17,53.73
             399.17,55.88 399.35,56.51 400.81,56.73
             403.74,56.67 403.65,58.19 405.21,60.27
             411.00,63.34 407.21,66.48 409.21,69.47
             415.42,71.88 410.95,74.18 412.89,76.46
             418.48,76.46 410.95,76.46 410.95,76.46
             411.72,77.21 412.43,78.02 413.07,78.89
             411.83,78.16 410.62,77.36 409.47,76.51
             400.59,69.33 391.00,62.96 380.85,57.52
             376.01,53.68 371.01,50.03 365.85,46.58
             353.44,41.37 340.38,37.01 326.96,33.59
             323.77,32.86 320.91,31.96 318.04,31.15
             318.04,31.15 318.04,30.40 318.04,30.40
             319.12,31.30 320.06,32.38 320.80,33.59
             326.99,35.39 333.31,35.46 339.64,35.12
             339.76,35.10 339.88,35.09 340.00,35.09
             340.13,35.09 340.25,35.10 340.36,35.12
             344.36,36.39 348.46,35.12 352.78,35.12
             355.95,35.26 358.98,35.96 361.77,37.11
             363.27,37.76 364.85,38.33 366.46,38.81
             366.92,38.95 367.41,39.03 367.93,39.03
             369.06,39.03 370.10,38.65 370.94,38.02
             371.24,37.83 371.44,37.50 371.44,37.12
             371.44,36.92 371.38,36.72 371.28,36.56
             371.19,36.42 371.07,36.31 370.94,36.22
             370.46,35.89 369.93,35.61 369.38,35.39
             366.46,34.29 363.53,33.25 360.61,32.16
             356.91,30.80 353.25,29.34 349.54,28.01
             347.29,27.20 344.99,26.54 342.71,25.81
             341.09,25.38 339.50,24.85 337.95,24.24
             334.48,22.83 330.83,21.68 327.07,20.82
             325.39,20.40 323.82,19.77 322.25,19.23
             322.25,19.23 328.74,19.23 328.74,19.23
             343.22,23.35 357.37,28.43 371.41,33.91
             374.29,35.05 377.15,36.25 380.00,37.51
             379.80,36.21 380.95,36.84 382.05,37.53 Z
           M 180.37,408.19
           C 180.38,408.45 180.39,408.73 180.39,409.00
             180.39,409.28 180.38,409.55 180.37,409.82
             179.22,419.39 179.93,429.60 209.40,413.78
             216.98,423.78 179.93,423.78 179.93,423.78
             179.95,424.08 179.96,424.39 179.96,424.70
             179.96,425.48 179.89,426.25 179.77,426.99
             179.44,429.35 177.89,431.31 175.77,432.21
             174.71,432.71 173.57,433.08 172.39,433.33
             171.82,433.46 171.22,433.52 170.60,433.52
             167.03,433.52 163.99,431.25 162.85,428.08
             162.57,427.43 162.36,426.75 162.22,426.04
             161.37,421.82 160.76,417.41 160.42,412.93
             159.99,405.21 159.30,397.57 158.78,389.93
             158.53,386.34 158.37,382.74 158.30,379.14
             158.30,377.68 158.45,376.25 158.73,374.87
             159.15,372.29 160.89,370.15 163.23,369.17
             165.10,368.28 167.21,367.78 169.43,367.78
             169.70,367.78 169.97,367.79 170.23,367.80
             170.27,367.80 170.31,367.80 170.35,367.80
             173.00,367.80 175.27,369.42 176.23,371.72
             176.90,373.21 177.42,374.81 177.74,376.47
             180.11,403.58 180.18,405.99 180.37,408.19 Z
           M 129.62,405.38
           C 129.28,408.48 128.91,411.56 128.62,414.64
             128.20,419.05 127.72,423.46 127.46,427.86
             127.17,433.33 137.00,429.38 141.71,429.38
             142.00,430.69 142.16,432.07 142.16,433.48
             142.16,433.78 142.15,434.08 142.14,434.38
             142.04,438.30 139.16,441.54 135.39,442.17
             134.60,442.31 133.78,442.38 132.93,442.38
             132.10,442.38 131.28,442.31 130.48,442.17
             127.02,441.81 124.49,440.17 123.29,436.69
             121.29,430.85 119.98,425.00 120.59,418.70
             120.11,413.05 110.81,419.93 105.39,413.10
             106.54,401.91 107.18,396.34 107.18,396.34
             107.34,395.13 107.59,393.96 107.94,392.83
             108.51,390.97 109.66,389.35 111.17,388.18
             111.99,387.48 113.06,387.05 114.23,387.05
             114.32,387.05 114.40,387.05 114.49,387.06
             115.08,387.02 115.69,387.00 116.30,387.00
             116.91,387.00 117.51,387.02 118.11,387.05
             123.21,393.75 120.75,394.78 126.66,394.78
             126.76,395.75 126.81,396.75 126.81,397.77
             126.81,398.58 126.78,399.38 126.72,400.17
             126.72,400.17 129.62,405.38 129.62,405.38 Z
           M 156.22,407.74
           C 156.06,409.40 156.00,411.09 155.73,412.74
             154.90,418.15 154.47,423.80 154.47,429.54
             154.47,431.12 154.50,432.68 154.57,434.24
             154.70,439.30 164.56,448.23 164.56,448.23
             164.59,448.61 164.61,449.01 164.61,449.41
             164.61,451.22 164.32,452.97 163.79,454.60
             163.33,456.25 162.00,457.54 160.33,457.94
             157.83,460.28 154.47,461.72 150.77,461.72
             146.40,461.72 142.49,459.71 139.93,456.57
             138.06,455.50 136.65,453.71 136.08,451.58
             136.01,451.35 135.95,451.11 135.90,450.89
             135.12,445.55 134.41,440.25 134.90,434.82
             134.46,429.54 129.56,426.90 120.20,426.90
             120.20,422.59 119.84,417.97 107.01,425.13
             103.39,420.13 119.84,420.13 119.84,420.13
             119.94,418.68 120.13,417.26 120.40,415.86
             120.71,414.03 122.10,412.56 123.89,412.13
             125.12,411.75 126.44,411.54 127.80,411.54
             130.84,411.54 133.64,412.55 135.89,414.25
             136.98,415.17 137.86,416.32 138.47,417.63
             155.92,401.93 156.20,404.24 156.22,407.74 Z
           M 83.16,438.52
           C 83.71,433.41 84.16,428.27 84.83,423.18
             85.30,419.45 85.55,415.58 85.55,411.64
             85.55,411.19 85.54,410.73 85.54,410.28
             85.54,410.28 85.54,400.93 85.54,400.93
             85.53,400.79 85.53,400.64 85.53,400.48
             85.53,397.47 86.80,394.75 88.83,392.84
             89.83,391.94 90.70,390.98 91.64,390.06
             95.50,386.26 100.73,386.90 103.99,388.51
             105.20,389.08 106.16,390.08 106.68,391.31
             107.47,393.00 107.91,394.89 107.91,396.88
             107.91,398.49 107.62,400.04 107.10,401.46
             105.70,405.56 104.64,409.87 103.95,414.30
             102.89,420.17 102.32,426.31 102.29,432.58
             102.29,438.10 102.24,443.61 102.16,449.12
             102.16,452.79 100.84,454.32 97.24,455.04
             96.51,455.17 95.75,455.24 94.97,455.24
             92.13,455.24 89.49,454.33 87.34,452.80
             85.91,451.73 84.84,450.20 84.35,448.43
             83.49,446.10 83.03,443.56 83.03,440.91
             83.03,440.11 83.07,439.31 83.16,438.52
             83.16,438.52 83.16,438.52 83.16,438.52 Z
           M 235.88,421.29
           C 235.51,421.93 235.30,422.68 235.30,423.48
             235.30,424.09 235.42,424.68 235.65,425.21
             236.34,427.67 236.72,430.30 236.72,433.02
             236.72,433.38 236.71,433.74 236.70,434.10
             236.84,439.34 237.49,444.45 238.62,449.39
             238.91,450.51 239.14,451.68 239.30,452.88
             239.33,453.16 239.35,453.45 239.35,453.75
             239.35,457.27 236.85,460.21 233.54,460.90
             232.45,461.19 231.31,461.34 230.12,461.34
             228.07,461.34 226.13,460.88 224.39,460.05
             221.49,458.87 219.34,456.24 218.83,453.06
             218.34,450.54 218.05,447.90 217.99,445.21
             217.91,436.21 216.99,427.27 216.47,418.31
             216.27,414.75 215.55,411.34 214.40,408.13
             213.99,407.05 213.65,405.91 213.39,404.75
             212.23,399.18 215.06,395.59 220.73,395.29
             220.97,395.28 221.22,395.27 221.47,395.27
             227.68,395.27 232.90,399.52 234.38,405.27
             234.79,407.50 237.38,406.46 239.15,406.46
             239.75,406.22 240.33,405.92 240.86,405.56
             241.32,405.18 241.72,404.72 242.02,404.20
             244.18,400.60 247.37,398.09 250.59,395.59
             256.46,391.04 262.36,386.54 268.30,382.10
             274.75,377.14 280.20,370.94 284.29,363.86
             285.61,361.57 287.07,359.36 288.63,357.24
             289.76,355.69 291.07,354.29 292.55,353.07
             299.02,347.89 306.43,343.83 314.48,341.17
             315.50,340.86 316.57,340.64 317.68,340.52
             319.82,340.22 321.97,340.02 324.10,339.67
             324.78,339.57 325.48,339.51 326.20,339.51
             328.59,339.51 330.84,340.12 332.81,341.19
             335.60,342.62 336.88,345.19 337.24,348.19
             337.32,348.92 337.36,349.69 337.36,350.46
             337.36,351.52 337.28,352.56 337.13,353.58
             336.57,357.03 333.58,359.67 329.97,359.67
             329.58,359.67 329.19,359.64 328.82,359.58
             328.56,359.54 328.30,359.49 328.05,359.42
             326.54,359.01 325.05,358.42 323.62,357.91
             322.50,357.47 321.27,357.23 319.99,357.23
             318.81,357.23 317.68,357.43 316.63,357.81
             313.67,358.90 310.99,360.56 308.72,362.66
             302.88,367.94 298.02,374.30 294.47,381.42
             292.82,384.39 290.75,387.09 288.34,389.44
             283.40,394.64 278.05,399.45 272.36,403.83
             267.85,407.43 263.44,411.11 259.12,414.88
             257.92,416.05 256.87,417.37 256.00,418.80
             254.69,420.81 252.82,422.42 250.61,423.41
             247.60,424.73 244.51,415.98 249.01,422.51
             248.01,420.88 246.93,420.05 245.91,420.05
             244.88,419.83 243.80,419.71 242.69,419.71
             240.24,419.71 237.93,420.28 235.88,421.28
             235.88,421.28 235.88,421.29 235.88,421.29 Z
           M 48.82,255.13
           C 48.79,255.60 48.77,256.08 48.77,256.56
             48.77,259.80 49.57,262.86 50.97,265.54
             53.30,269.76 57.23,272.96 61.95,274.32
             54.91,275.71 49.61,281.91 49.61,289.35
             49.61,297.81 56.47,304.66 64.93,304.66
             65.95,304.66 66.94,304.56 67.90,304.37
             67.90,304.37 64.21,304.37 64.21,304.37
             68.41,301.85 72.50,299.11 76.57,296.38
             79.05,294.70 81.37,292.78 83.77,291.04
             86.98,288.83 90.87,287.54 95.06,287.54
             95.13,287.54 95.19,287.54 95.25,287.54
             101.45,287.36 105.90,295.64 103.62,300.62
             103.03,302.09 102.00,303.34 100.71,304.22
             92.86,309.31 85.08,314.48 77.12,319.40
             74.27,321.08 71.22,322.49 68.03,323.58
             64.67,324.91 60.99,325.64 57.14,325.64
             54.41,325.64 51.77,325.27 49.25,324.58
             45.58,323.53 41.92,322.33 38.26,321.05
             30.41,318.58 24.71,311.24 24.71,302.57
             24.71,291.87 33.39,283.20 44.09,283.20
             44.73,283.20 45.35,283.23 45.98,283.29
             39.28,277.15 34.73,268.70 33.60,259.20
             31.89,245.89 34.98,233.70 41.70,222.30
             44.32,217.82 48.00,214.04 52.39,211.30
             56.68,208.60 61.37,206.44 66.34,204.94
             69.43,204.02 72.74,203.53 76.16,203.53
             78.28,203.53 80.37,203.72 82.39,204.08
             90.17,205.35 97.46,207.97 103.80,212.87
             106.34,214.87 109.01,216.70 111.48,218.78
             113.29,220.35 114.98,222.06 116.54,223.88
             118.82,226.50 121.04,229.20 123.17,231.88
             123.99,232.89 124.69,234.01 125.25,235.22
             125.85,236.37 126.19,237.68 126.19,239.07
             126.19,241.48 125.17,243.64 123.55,245.17
             122.20,243.80 124.89,243.17 125.60,243.17
             124.31,244.57 122.45,245.44 120.39,245.44
             119.18,245.44 118.04,245.14 117.04,244.61
             114.32,243.29 111.95,241.36 110.10,239.00
             108.99,237.58 108.00,236.07 106.86,234.69
             105.12,232.56 103.27,230.49 101.47,228.39
             93.35,227.82 88.06,224.82 82.38,222.39
             79.59,221.20 76.52,220.50 73.30,220.40
             73.11,220.39 72.91,220.39 72.72,220.39
             68.65,220.39 64.89,221.73 61.87,223.99
             57.04,227.43 54.28,232.35 52.21,237.58
             50.03,242.73 48.82,248.41 48.82,254.38
             48.82,254.63 48.82,254.88 48.82,255.13
             48.82,255.13 48.82,255.13 48.82,255.13 Z
           M 392.16,42.56
           C 392.50,42.66 392.83,42.78 393.16,42.91
             402.08,47.32 411.03,51.69 419.03,57.72
             421.73,59.72 424.58,61.49 427.33,63.42
             431.31,66.09 435.09,69.06 438.63,72.28
             444.42,77.84 449.84,83.77 454.86,90.04
             460.10,96.53 464.12,103.75 467.86,111.15
             470.80,116.80 473.28,122.77 475.27,128.96
             477.53,135.89 479.17,143.19 480.16,150.70
             481.22,159.13 482.16,167.48 481.96,175.88
             481.85,181.39 481.32,186.87 480.96,192.38
             480.67,196.45 480.32,200.47 479.96,204.57
             479.92,205.42 479.81,206.25 479.64,207.06
             477.84,215.25 476.10,223.46 474.15,231.62
             471.85,241.30 468.50,250.69 465.16,260.08
             462.60,267.30 459.97,274.50 457.27,281.66
             456.27,284.23 455.09,286.71 453.95,289.22
             453.15,290.96 452.23,292.65 451.45,294.41
             446.76,305.07 440.86,315.09 434.96,325.12
             432.77,328.86 430.10,332.29 427.03,335.32
             425.57,336.74 424.13,338.18 422.71,339.64
             420.04,342.37 417.00,344.73 413.66,346.64
             408.09,349.73 402.77,353.23 397.72,357.08
             394.73,359.43 391.36,361.39 388.14,363.46
             387.23,364.02 386.27,364.51 385.27,364.92
             381.74,366.46 378.21,367.92 374.72,369.50
             372.13,369.12 369.99,367.33 369.14,364.93
             365.28,363.65 363.65,360.57 365.03,356.73
             366.79,351.88 369.46,347.74 374.23,345.20
             377.71,343.34 381.04,341.20 384.46,339.20
             385.57,338.51 386.74,337.90 387.97,337.40
             392.92,335.61 397.11,332.62 401.35,329.70
             403.03,328.54 404.67,327.32 406.35,326.20
             413.04,321.81 420.69,313.72 424.96,306.97
             429.50,299.61 433.42,291.79 436.65,283.62
             438.65,278.75 441.01,274.16 442.95,269.41
             446.83,260.12 451.05,250.95 454.05,240.71
             452.12,241.83 450.14,242.39 448.19,242.39
             448.84,241.02 449.42,239.58 449.93,238.10
             451.38,233.00 452.75,227.92 454.10,222.82
             453.64,221.09 454.02,219.33 455.23,217.56
             454.59,213.56 456.23,209.47 457.08,205.50
             459.03,196.06 459.14,186.51 459.58,176.39
             448.59,175.08 437.85,172.72 427.20,172.72
             426.88,171.68 426.47,170.68 425.97,169.73
             425.35,167.68 424.83,165.58 424.09,163.57
             422.95,160.57 421.67,157.58 420.42,154.58
             433.70,141.19 442.76,123.61 445.43,104.00
             441.74,99.12 437.73,94.50 433.44,90.16
             430.44,87.22 427.25,84.52 424.14,81.72
             423.24,80.92 422.41,80.09 421.54,79.27
             420.91,78.40 420.20,77.59 419.42,76.85
             417.14,74.92 414.85,72.98 412.43,71.24
             409.43,69.08 406.30,67.08 403.22,65.03
             401.14,63.64 399.62,62.08 399.67,59.17
             397.29,58.68 396.97,56.18 396.74,57.45
             396.57,55.36 396.20,55.32 394.57,55.32
             394.59,54.91 394.60,54.49 394.60,54.06
             394.60,53.64 394.59,53.22 394.57,52.81
             394.57,52.79 394.57,52.76 394.57,52.74
             394.57,51.61 393.94,50.62 393.02,50.11
             390.77,48.97 388.79,47.39 387.19,45.47
             386.65,45.01 386.04,44.61 385.39,44.30
             383.46,43.10 381.51,41.93 379.61,40.70
             379.28,40.48 379.11,40.01 378.88,39.70
             377.98,38.55 380.96,38.80 381.99,38.64
             381.99,38.64 381.99,38.64 381.99,38.64
             383.43,40.64 385.51,41.24 387.78,41.52
             387.78,41.52 387.78,41.52 387.78,41.52
             388.80,42.21 390.03,42.61 391.35,42.61
             391.63,42.61 391.89,42.59 392.16,42.56
             392.16,42.56 392.16,42.56 392.16,42.56 Z
           M 88.48,334.65
           C 88.43,333.65 88.41,332.62 88.41,331.58
             88.41,324.50 89.59,317.70 91.75,311.35
             94.67,302.87 100.10,296.64 108.40,293.04
             111.48,291.65 114.91,290.87 118.52,290.85
             118.52,290.85 121.40,290.85 121.40,290.85
             126.71,290.58 131.23,292.47 135.28,295.79
             141.06,300.45 146.29,305.77 150.85,311.64
             153.43,315.06 155.61,318.82 157.30,322.82
             157.84,323.99 158.18,325.28 158.30,326.64
             158.41,327.81 158.47,329.04 158.47,330.27
             158.47,332.95 158.19,335.57 157.67,338.09
             156.11,344.49 152.58,349.45 146.56,352.31
             141.65,354.75 136.13,356.18 130.29,356.31
             128.23,356.31 126.08,350.05 126.08,350.05
             125.83,350.06 125.57,350.07 125.31,350.07
             124.48,350.07 123.66,350.03 122.85,349.95
             119.96,349.79 117.55,347.81 116.76,345.13
             115.94,343.08 115.49,340.84 115.49,338.48
             115.49,338.23 115.50,337.98 115.51,337.73
             115.22,334.14 117.31,331.40 121.02,330.13
             122.44,329.57 124.00,329.26 125.63,329.26
             126.71,329.26 127.75,329.39 128.75,329.65
             129.93,333.09 131.97,336.14 134.61,338.52
             134.61,338.52 133.15,343.35 133.15,343.35
             133.15,343.35 127.66,356.63 127.66,356.63
             127.66,356.63 123.67,356.63 123.67,356.63
             120.67,351.52 117.17,346.85 111.97,343.70
             111.15,343.21 110.43,342.56 109.63,342.04
             108.66,341.35 107.65,340.72 106.59,340.15
             102.08,337.89 98.34,339.15 96.20,343.75
             94.64,347.03 93.60,350.63 93.20,354.42
             92.97,356.53 92.85,358.73 92.85,360.96
             92.85,363.73 93.03,366.46 93.39,369.14
             94.13,374.01 94.87,378.86 95.40,383.74
             95.55,385.15 95.63,386.61 95.63,388.09
             95.63,389.05 95.59,389.99 95.53,390.93
             95.35,393.77 93.73,395.43 90.91,396.13
             86.81,397.13 83.12,385.72 87.91,385.72
             85.80,383.48 84.25,380.71 83.47,377.64
             82.45,373.33 81.84,368.80 81.67,364.16
             88.40,338.55 88.48,336.63 88.48,334.65 Z
           M 197.13,199.97
           C 202.79,200.04 208.28,200.83 213.51,202.24
             225.85,205.49 237.65,210.19 249.26,215.40
             258.53,219.45 267.35,224.40 275.60,230.10
             279.13,232.40 282.30,235.21 284.99,238.43
             286.40,240.08 287.90,241.65 289.49,243.12
             293.68,247.19 297.20,251.96 299.84,257.24
             300.36,258.35 300.95,259.39 301.57,260.42
             303.74,264.04 305.01,268.27 305.10,272.78
             305.16,273.87 305.20,275.01 305.20,276.15
             305.20,280.50 304.73,284.74 303.86,288.83
             302.55,295.09 299.71,300.79 295.75,305.51
             294.41,304.32 297.25,303.71 298.02,304.50
             297.24,305.72 296.76,306.26 293.29,309.83
             283.29,319.82 276.77,322.88 276.77,322.88
             270.34,325.71 263.35,327.60 256.03,328.35
             257.90,335.50 258.90,343.10 258.90,350.92
             258.90,374.66 249.73,396.26 234.75,412.38
             234.75,412.38 230.50,479.63 230.50,479.63
             230.50,479.63 213.93,479.63 213.93,479.63
             212.83,478.51 211.74,477.40 210.62,476.32
             206.15,472.00 202.21,467.14 198.93,461.83
             195.24,455.90 191.66,449.95 187.99,444.03
             186.88,442.04 185.60,440.15 184.19,438.37
             180.50,434.11 182.60,429.38 185.66,427.04
             190.86,423.05 197.36,423.71 201.28,428.94
             203.43,431.94 205.37,435.10 207.07,438.41
             211.11,446.23 216.03,453.54 221.68,460.21
             224.79,463.72 228.10,467.05 231.58,470.20
             233.35,471.77 235.37,473.08 237.56,474.07
             247.06,478.54 257.68,481.09 268.89,481.20
             275.86,494.88 268.50,501.47 266.43,501.47
             269.21,500.64 271.89,499.56 274.43,498.26
             280.05,495.22 284.52,490.33 287.02,484.41
             288.23,481.72 289.14,478.85 289.72,475.85
             290.00,474.59 290.15,473.25 290.15,471.88
             290.15,467.86 288.88,464.13 286.72,461.08
             283.90,456.90 280.53,453.11 276.73,449.81
             311.43,430.20 334.85,392.96 334.85,350.25
             334.85,299.81 302.18,257.00 256.84,241.81
             241.37,232.93 224.46,226.20 206.56,222.03
             203.20,221.22 199.64,220.79 195.99,220.79
             194.43,220.79 192.90,220.87 191.38,221.02
             186.01,221.39 181.18,223.73 177.61,227.32
             175.56,229.25 174.27,231.98 174.24,235.00
             174.24,235.05 174.24,235.11 174.24,235.16
             174.24,236.45 174.12,237.72 173.88,238.94
             173.46,241.75 171.05,243.91 168.12,243.94
             167.42,244.01 166.70,244.05 165.96,244.05
             165.24,244.05 164.51,244.01 163.81,243.94
             159.90,243.73 156.71,240.84 156.04,237.07
             155.73,235.85 155.56,234.56 155.56,233.23
             155.56,232.96 155.57,232.70 155.58,232.44
             155.99,225.19 158.84,218.59 163.32,213.45
             169.61,206.01 177.71,202.09 187.24,200.86
             190.44,200.47 193.79,200.28 197.13,199.97 Z
           M 71.85,69.17
           C 71.85,69.17 70.37,71.33 70.37,71.33
             70.37,71.33 71.85,69.17 71.85,69.17 Z
           M 74.68,65.51
           C 74.21,67.06 73.18,68.36 71.81,69.18
             72.42,67.71 73.42,66.44 74.68,65.51
             74.68,65.51 74.68,65.51 74.68,65.51 Z
           M 327.35,17.38
           C 327.35,17.38 321.62,16.62 321.62,16.62
             321.62,16.58 321.63,16.55 321.63,16.51
             321.63,16.32 321.60,16.14 321.56,15.97
             323.62,15.93 325.65,16.07 327.35,17.38 Z
           M 301.44,10.91
           C 301.44,10.91 295.68,7.31 295.68,7.31
             295.68,7.31 299.82,8.31 299.82,8.31
             303.70,14.50 301.50,17.64 302.09,17.64
             304.72,18.03 307.17,19.01 309.29,20.42
             310.29,21.10 311.29,21.66 312.29,22.27
             310.67,21.92 309.04,21.62 307.43,21.22
             310.19,13.12 305.84,11.99 301.44,10.91 Z
           M 263.29,3.00
           C 263.29,3.00 263.29,2.25 263.29,2.25
             268.08,2.09 272.76,3.25 277.45,3.97
             283.54,4.97 289.60,6.21 295.68,7.33
             295.68,7.33 301.44,10.93 301.44,10.93
             300.60,10.88 299.78,10.77 298.98,10.61
             292.85,8.99 286.61,8.04 280.58,6.11
             278.13,5.42 275.55,4.88 272.91,4.51
             271.35,4.22 269.86,3.99 268.29,3.73
             268.29,3.73 263.29,3.02 263.29,3.02
             263.29,3.02 263.29,3.00 263.29,3.00 Z" />
        </svg>

    );
}


function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

export default class DenIcon extends Component {
    render () {
        return (<DenIconFunction/>)

    }
}



