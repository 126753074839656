import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import MicIcon from '@material-ui/icons/Mic';

const styles = {
    root: {
        width: '100%',
        display: 'block', // use content(s?) for full screen width display if not within other accordions
        maxWidth: '1480px',

    },
    accordionMaster: {
        fontVariant: 'uni-case',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#3b444b',
        borderRadius: '1px',
        fontWeight: '100',
        color: '#efd280',
    },

    accordionComponent: {
        maxWidth: '1480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '2px',

    },
    accordionHeader: {
        //backgroundColor: '#515D67',
        backgroundColor: '#eee',
        borderRadius: '2px',

    },
    accordionHeaderText: {
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        marginBottom: '0%',
        letterSpacing: '0.039rem',
        //color: '#f2f3f4',

    },
    accordionBody: {
        fontSize: '0.9rem',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
       // marginBottom: '0%'
    },
    accordionMasterBody: {
        //backgroundColor: '#f8f8ff',
    },
    accordionIcon: {
        opacity: .8,
    },
    whitespace: {
        position: 'absolute',
    },
};
//                            {item.icon === 'mic' ? ( <MicIcon/>) : null}

class AccordionElement extends Component {

    render () {
        const {classes} = this.props.props;
        const {props} = this.props.props;
        console.log('This is props (aka items): ', this.props)
        props.map((item) => {console.log('Item Mapped: ', item)});
        return (
            <Container className={classes.root}>
                {props.map((item) => (
                    <Accordion className={classes.accordionComponent} id={'accordionComponent'}>
                        <AccordionSummary className={classes.accordionHeader} expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">

                            {item.icon_type === 'mic' ?
                                (
                                    <Typography className={classes.accordionHeaderText}>
                                        <MicIcon className={classes.accordionIcon}/>
                                        &nbsp;{item.name}
                                    </Typography>
                                ) :
                                <Typography className={classes.accordionHeaderText}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                                </Typography>
                            }

                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Typography className={classes.accordionBody}>{item.info}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        )
    }
}

class SimpleAccordion extends Component {

    render() {
        const {classes} = this.props;
        const {props} = this.props;
        return (
            <Accordion className={classes.accordionMaster} id={'accordionMaster'}>

                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.accordionHeaderText}>Explore Our Inventory</Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails className={classes.accordionMasterBody}>
                    <AccordionElement props={this.props}/>
                </AccordionDetails>

            </Accordion>
        );
    }
}


export default withStyles(styles, { withTheme: false })(SimpleAccordion);
