import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import MicIcon from '@material-ui/icons/Mic';

const styles = {
    root: {
        width: '100%',
        display: 'block', // use content(s?) for full screen width display if not within other accordions
        maxWidth: '1480px',

    },
    accordionMaster: {
        fontVariant: 'uni-case',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#3b444b',
        borderRadius: '1px',
        fontWeight: '100',
        color: '#efd280',
    },

    accordionComponent: {
        maxWidth: '1480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '2px',

    },
    accordionHeader: {
        //backgroundColor: '#515D67',
        backgroundColor: '#eee',
        borderRadius: '2px',

    },
    accordionHeaderText: {
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        marginBottom: '0%',
        letterSpacing: '0.039rem',
        //color: '#f2f3f4',

    },
    accordionBody: {
        fontSize: '0.9rem',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        // marginBottom: '0%'
    },
    accordionMasterBody: {
        //backgroundColor: '#f8f8ff',
    },
    accordionIcon: {
        opacity: .8,
    },
    whitespace: {
        position: 'absolute',
    },
};
//                            {item.icon === 'mic' ? ( <MicIcon/>) : null}

class AccordionChildren extends Component {

    render () {
        const {classes} = this.props;
        const {items} = this.props;
        console.log('Adv Accordion Props: ', this.props);
        console.log('Adv Accordion Props Items: ', this.props.items);
        return (
            <Container className={classes.root}  id={'RemovePaddingFromChildren'}>
                {items.map((item, key) => (
                    <Accordion className={classes.accordionComponent} id={'accordionMaster'} key={key}>
                        <AccordionSummary className={classes.accordionHeader} expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">

                            {item.icon_type === 'mic' ?
                                (
                                    <Typography className={classes.accordionHeaderText}>
                                        <MicIcon className={classes.accordionIcon}/>
                                        &nbsp;{item.title}
                                    </Typography>
                                ) :
                                <Typography className={classes.accordionHeaderText}>
                                    {item.title}
                                </Typography>
                            }

                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Typography className={classes.accordionBody}>{item.details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        )
    }
}



export default withStyles(styles, { withTheme: false })(AccordionChildren);
//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;