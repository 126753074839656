

import React, { Component, createClass } from 'react';
import ReactDOM from 'react-dom';

// Already included! Does not need to be included here
//import './Assets/css/default.min.css';

import { Link } from "react-router-dom";

import logo from "./../../favicon.ico";

import ClearIcon from '@material-ui/icons/Clear';
import Icon from '@material-ui/core/Icon';
import SimplePopover from "../features/PopoverMenu";
import Popover from "@material-ui/core/Popover";
import Backdrop from '@material-ui/core/Backdrop';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

// Rendering happens first
// ComponentDidMount next
export default class DesktopHorizontalNavBackup extends Component<> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            backdrop_open: false,
            firstTimeRendering: (localStorage.getItem("popoverShown") == 'false'),
        };
    }

    toggleMenu = (event) => {
        const current_target = event.currentTarget;

        this.setState(state => ({
            //popover_open: !this.state.popover_open,
            open: !this.state.open,
            //anchorE1: this.state.anchorE1 ? null : current_target
            //anchorEl: current_target
        }));
        console.log('Menu Click Event: ', event.currentTarget);
        console.log('Anchor E1: ', current_target);
    };

    handleClick = (event) => {

        this.setState(state => ({
            anchorE1: event.currentTarget
        }));
    };

    handleClose = () => {
        this.setState(state => ({
            anchorEl: null,
            popover_open: false,
            backdrop_open: false,
        }));
    };

    // Sets state anchorE1, triggered onLoad of button, instead of onLoad when button is pressed
    // This allows for the location of the button to be obtained, so in the event that Popover Advice has not been
    // shown yet, the state anchorE1 will be set without the button being pressed
    updateBackdropAndPopoverStates = () => {
        const popoverAdviceShown = (localStorage.getItem("popoverShown") == 'true');
        console.log('popoverAdviceShown: ', popoverAdviceShown);
        const shouldPrepareAdvice = true;//!popoverAdviceShown; // if popover advice has not been shown yet
        if (shouldPrepareAdvice) {localStorage.setItem("popoverShown", (shouldPrepareAdvice).toString())}
        console.log("shouldPrepareAdvice: ", shouldPrepareAdvice);
        const anchor_formula = shouldPrepareAdvice ? document.querySelector("#popover-wrapped-component > button"): null;
        this.setState(state => ({
            backdrop_open: true,
            popover_open: shouldPrepareAdvice,
            open: false,
            anchorE1: anchor_formula
        }));
        console.log('Set AnchorE1 as anchor_formula: ', anchor_formula);
        // anchor_formula:
        // shouldPrepareAdvice == true ==> set anchor as the button
        //                     == false ==> set as null, because it has already been shown
    };

    setIsFirstTimeRendering = () => {
        this.setState(state => ({ firstTimeRendering: false}));
    };


    prepareModal = () => {
        console.log('\n\n\n\nPreparing MOdal');
        if (this.state.firstTimeRendering) {
            this.updateBackdropAndPopoverStates(); // set anchor element as button before componentMount
            this.setIsFirstTimeRendering(); // set to false now
        }
    };

    componentDidMount = () => {
        console.log('Mounted');
        const popoverAdviceShownMount = (localStorage.getItem("popoverShown") == 'true');
        console.log('--> popoverAdviceShownMount: ', popoverAdviceShownMount);
        this.setState(state => ({
            popover_open: this.state.popover_open, // nothing to change on componentMount, handled by setAnchorElement
            open: false,
            anchorE1: this.state.anchorEl // nothing to change on componentMount, handled by setAnchorElement
        }));

        console.log('--> this.state.anchorE1: ', this.state.anchorEl);
    };

    componentDidUpdate = () => {
        console.log('Component Update');
        this.prepareModal();
    };

    // Renders on every state change
    render () {
        console.log("Rendering");


        const linksArray = [
            { name: "home", url: "#", id: "1" },
            { name: "log in", url: "#", id: "2"},
            { name: "photos", url: "#", id: "3" },
            { name: "contact", url: "#", id: "4" },
            { name: "download", url: "#", id: "5"}
        ];
        const socialArray = [
            {
                icon: "fa fa-github-square",
                url: "https://github.com/matthewvincent"
            },{
                icon: "fa fa-instagram",
                url: "https://www.instagram.com/middlestates/"
            },{
                icon: "fa fa-tumblr-square",
                url: "http://matthewvincentphotography.com/"
            }
        ];


        //const popover_open = Boolean(this.state.anchorEl);
        //const id = popover_open ? 'simple-popover' : undefined;
        //let r = this.state.anchorEl;

        let popover_anchor_point = document.querySelector("#popover-wrapped-component > button");
        return (

            <header id={'new-header'}>
                <div className="container-fluid" id={'horizontal-nav-container'}>

                    <div id={'popover-wrapped-component'}>

                        <button
                            //toggle={this.toggleMenu}
                            //variant={"contained"}
                            className={this.state.open
                                ? "menu-toggle close-button"
                                : "menu-toggle "}
                            color={"primary"}
                            onClick={this.toggleMenu}
                        > <Icon className="fa fa-plus"><ClearIcon/></Icon></button>

                        {this.state.firstTimeRendering || this.state.popover_open ? (
                            <div>
                                <Backdrop className={'backdrop'} open={this.state.backdrop_open} onClick={this.handleClose}>
                                    <Popover
                                        id={'simple-popover'}
                                        open={true}
                                        anchorEl={popover_anchor_point}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <p  id={'menu-popover-p'}>
                                            <Icon id={'menu-popover-icon'}><NavigateBeforeIcon/></Icon>
                                            open menu by clicking here
                                        </p>
                                    </Popover>
                                </Backdrop>
                            </div>


                        ): null}

                    </div>
                    <Panel
                        open={this.state.open}
                        links={linksArray}
                        socialLinks={socialArray}
                    />


                </div>
            </header>
        );
    }
}
/**
 export class Button extends Component {
    render () {
        console.log('Button Class Props: ', this.props);

        return (
            <button
                className={this.props.open
                    ? "menu-toggle close-button"
                    : "menu-toggle "}
                onClick={this.props.toggle}
                aria-describedby={this.props.ariadescribedby}
            > <Icon className="fa fa-plus"><ClearIcon/></Icon>
            </button>
        );
    }
}
 **/

class Panel extends Component {
    render() {
        return (
            <div
                className={this.props.open
                    ? "menu-wrapper menu-open"
                    : "menu-wrapper"}
            >
                <Links
                    links={this.props.links}
                    open={this.props.open}
                />

            </div>
        );
    }
}

class Links extends Component {
    render() {
        const linkList = this.props.links.map((link) => (
            <li className="link" id={link.id}>
                <a href={link.url}>{link.name}</a>
            </li>
        ));

        return (
            <div
                className={this.props.open
                    ? "links-wrapper"
                    : "links-wrapper links-wrapper-closed"}
            > <ul className="link-list">
                {linkList}
            </ul>
            </div>
        );
    }
}

class Social extends Component {
    render() {
        const socialLinks = this.props.socialLinks.map((link) => (
            <a href={link.url}>
                <i className= {link.icon} />
            </a>
        ));

        return (
            <div
                className={this.props.open
                    ? "social-wrapper social-open"
                    : "social-wrapper social-closed"}
            >
            </div>
        );
    }
}

/**
 * <Social
 socialLinks={this.props.socialLinks}
 open={this.props.open}
 />
 */

//{socialLinks}

//ReactDOM.render(<Menu/>, document.getElementById('menu'));
//export default Menu;


/**

 <div className="logo">
 <img src={logo} alt="Logo"/>
 </div>

 **/