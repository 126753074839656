import React, { Component } from 'react';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {

    PageTitleTypography: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
    },

};

class PageTitle extends Component<> {

    constructor(props) {
        super(props);
    }

    render () {
        const header_text = this.props.title.text;
        const {classes} = this.props;
        return (
            <Typography className={classes.PageTitleTypography} id={"pageHeaderText"} variant="h4" component="h5">
                {header_text}
            </Typography>
        );
    }
}

export default withStyles(styles, { withTheme: false })(PageTitle);

