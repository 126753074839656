import React, { Component, Fragment } from 'react';
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
import ImageGallery from "../imageComponent/ImageGallery";
import InitFingerprintJS from "../features/IPLogging";
import withStyles from "@material-ui/core/styles/withStyles";
import PageTitle from "../TextComponents/PageTitle";
import CircleMenu from "../headerComponent/headerMobileDropDown";
import DesktopHorizontalNav from "../headerComponent/DesktopHorizontalNav";
import DesktopHorizontalNavBackup from "../headerComponent/Backup-Semi-Working-Popover-HorizonatlNav";
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SimpleAccordion from "../features/SimpleAccordion"
import MicIcon from '@material-ui/icons/Mic';
import ForeheadOfSkull from "../headerComponent/ForeheadOfSkull";


const styles = {
    homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
    infoContainer: {
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: 'rgba(255,255,255,.95)',
        position: 'relative',
        borderRadius: '4px',
        flexGrow: 2,
        width: '100%',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        boxShadow: '2px 2px 3px #aaaaaa',
        maxWidth: '1480px',
        zIndex: 3,
        marginBottom: '1rem',
        //marginBottom: '2%',
    },
    pageHeaderText: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',
    },
    infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        lineHeight: '1.75rem',
        //paddingBottom: '0.5rem',
    },
    infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    accordionContainer: {
        paddingBottom: '5%',
    },
    accordionComponent: {
    }
};

class EquipmentPageB extends Component {
    //routeProps: ImagesProps;
    state = { modalIsOpen: false };
    done_retrieving = false;
    outside_render_images = undefined;



    render () {
        const equipment_data = [

            {name: 'SE X1', info: 'The X1 large diaphragm condenser mic is an all around performer, ' +
                    'with the ability to track acoustic instruments, ensembles, and vocals with detail and' +
                    ' clarity.', icon_type: 'mic'},

            {name: 'MXL 990', info: "Remains one of the industry's most ground-breaking microphones." +
                    " The first high quality condenser microphone to come into reach of working musicians," +
                    " the MXL 990 has a FET preamp and a large diaphragm for truly professional sound" +
                    "quality in both digital and analog recordings.", icon_type: 'mic'},

            {name: 'Blue Yeti ', info: "A tried and true classic.", icon_type: 'mic'},

            {name: 'Mackie HM-4', info: "4-channel Headphone Amplifier with Individual Level Controls", icon_type: 'none'},

            {name: 'Scarlett 2i2 ', info: "Two of the best performing mic preamps the Scarlett range has ever seen," +
                    " now with switchable air Mode to give your recordings a brighter" +
                    " and more open sound.", icon_type: 'none'},

            {name: "Keystation 49es",
                info: "Simple, powerful MIDI controller designed for sequencing music and playing virtual instruments",
                icon_type: 'none'},

            {name: "Studio Headsets",
                info: "We have several pairs of Audio-Technica studio-grade headphones.",
                icon_type: 'none'}


        ];
        const {classes} = this.props;
        // this.props has nothing to do with the image files/links
        return (


            <div>


                <PageTitle title={{text: 'Equipment'}}/>

                <Container className={classes.infoContainer}>

                    <p className={classes.infoSectionHeader} id={'equipmentInfoSectionHeader'} >
                        Provided Studio Equipment
                    </p>
                    <Divider className={classes.infoHeaderDivider}/>
                    <p className={classes.infoSectionBody} id={'infoSectionBody'}>
                        As is consistent with our instruments and facility as a whole, our equipment is not only pristine
                        but it is also of the utmost quality. At our studio we only use professional equipment.
                        Using only tried and true staples, the equipment that you will use to record is of an industry-grade
                        level.
                    </p>
                    <Divider className={classes.insetInfoDivider} variant="inset"/>

                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
                        Below listed is a brief description of the equipment that we have on-site. If you would like to
                        bring your own equipment, or perhaps you have questions pertaining to our ours, feel free to
                        contact us using the <i>Contact Us</i> page.
                    </p>
                    <Container className={classes.accordionContainer}>
                        <SimpleAccordion className={classes.accordionComponent} props={equipment_data}/>

                    </Container>

                </Container>

                <div id={'gallery-root'}>
                    <ImageGallery album_name={"Equipment_Prod"}/>
                    <InitFingerprintJS/>
                </div>

            </div>


        );
    }
}
export default withStyles(styles, { withTheme: false })(EquipmentPageB);

//<ThreeDimensionalLogo/>
/**
 *  <div className={"container"} id={"equipment-header-container"}>
 <ThreeDimensionalLogo/>
 <div className={"container"} id={"equipment-body-container"}>
 <ImageGallery album_name={"Instruments"}/>
 </div>
 <InitFingerprintJS/>
 </div>

 {window.screen.availWidth <= 738 ? (
                    <ForeheadOfSkull/>
                ) : null}


 */

//<FluidInstrumentsGallery className="carouselImageGallery"/>
//<img src="https://live.staticflickr.com/65535/50504608667_48b13e9044_b.jpg" width="745" height="1005" alt="n"/>
//<GoogleExample/>

