import React, { Component } from 'react';
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
import ImageGallery from "../imageComponent/ImageGallery";
import InitFingerprintJS from "../features/IPLogging";
import CircleMenu from "../headerComponent/headerMobileDropDown";
import Menu from "../headerComponent/headerNew";
import DesktopHorizontalNav from "../headerComponent/DesktopHorizontalNav";
import PageTitle from "../TextComponents/PageTitle";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import SimpleAccordion from "../features/SimpleAccordion";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = {
    homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
    infoContainer: {
        //backgroundColor: 'rgba(255,255,255,.8)',
        //backgroundColor: '#f8f8ff',
        backgroundColor: 'rgba(255,255,255,.95)',
        position: 'relative',
        borderRadius: '4px',
        flexGrow: 2,
        width: '100%',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        boxShadow: '2px 2px 3px #aaaaaa',
        maxWidth: '1480px',
        zIndex: 3,
        marginBottom: '1rem',
        //marginBottom: '2%',
    },
    pageHeaderText: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',
    },
    infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        lineHeight: '1.75rem',
        //paddingBottom: '0.5rem',
    },
    infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    accordionContainer: {
        paddingBottom: '5%',
    },
    accordionComponent: {
    }
};

class InstrumentPage extends Component {
    //routeProps: ImagesProps;
    state = { modalIsOpen: false };
    done_retrieving = false;
    outside_render_images = undefined;



    render () {
        const equipment_data = [
            {name: 'Coming Soon...', info: 'Some of our equipment inventory, however, is listed. ', icon_type: 'mic'},
        ];

        const {classes} = this.props;
        // this.props has nothing to do with the image files/links
        return (


            <div>


                <PageTitle title={{text: 'INSTRUMENTS'}}/>

                <Container className={classes.infoContainer}>

                    <p className={classes.infoSectionHeader} id={'instrumentsInfoSectionHeader'} >
                        On-site Studio Instruments
                    </p>
                    <Divider className={classes.infoHeaderDivider}/>
                    <p className={classes.infoSectionBody} id={'infoSectionBody'}>
                        When you book a session with us, just as you get access to our studio equipment, you additionally
                        receive access to the many instruments we have on site. Ranging from electric and acoustic guitars to keyboards,
                        our study has a wide variety of instruments to which you may use at your discretion. If you are concerned about
                        any compatibility issues between your personal instruments and our equipment, rest assured that
                        this is almost never the case. With that said, however, we are always answering our customers
                        questions so feel free to reach out.
                    </p>
                    <Divider className={classes.insetInfoDivider} variant="inset"/>

                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
                        Below listed is a brief description of the instruments that we currently offer in our studio.
                    </p>
                    <Container className={classes.accordionContainer}>
                        <SimpleAccordion className={classes.accordionComponent} props={equipment_data}/>

                    </Container>

                </Container>

                <div id={'gallery-root'}>
                    <ImageGallery album_name={"Instruments_Prod"}/>
                    <InitFingerprintJS/>
                </div>

            </div>


        );
    }
}
export default withStyles(styles, { withTheme: false })(InstrumentPage);



//<FluidInstrumentsGallery className="carouselImageGallery"/>
//<img src="https://live.staticflickr.com/65535/50504608667_48b13e9044_b.jpg" width="745" height="1005" alt="n"/>
//<GoogleExample/>

//                    <Test {...this.props} />