import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hasLoaded, setHasLoaded] = React.useState(null);
    const [componentFromProps, setComponentFromProps] = React.useState(null);


    const handleClick = (event) => {
        console.log('Clicked Popover Button');
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log('Popover Button Closed');
        setAnchorEl(null);
    };


    /**
     * We can set the state here, but if we attempt to access the states that we set, nothing will have changed
     * While I am still learning about this, I can only figure that we must wait for the state to update, hence why we
     * are able to get the updated values in the useEffect() function, which is activated once the state has been updated
     *
     * Currently we have to specify that the component being passed is 'MenuButton', which while not ideal, suites the
     * purpose as I only intend on using this wrapper-styled-approach this one time.
     * --> In the future, alongside the component itself, e.g. MenuButton, we should also pass along with it the name
     *      of the component in a separated value within the props being passed... This will of course require that
     *      we include a way to obtain said defined name-value property before updating setComponentToWrap(), addressing
     *      the props in a way similar to something like: setComponentToWrap(props.props[{NameOfComponent}])
     *      Not sure if that would be syntactically viable. FYI. Would need to be messed around with.
     */




    useEffect(() => {
        // Update the document title using the browser API
        console.log('Mounted Menu Button');
        console.log('This is Component that has been set to be wrapped: ', componentFromProps); //state
        console.log("Has Loaded, now: ", hasLoaded);
        //document.title = `You clicked ${count} times`;
    });


    const handleHasLoaded = () => {
        setHasLoaded(true);
    };

    /** Believe this is called when the component is first-rendered; supporting this assumption is 'Has Loaded' appears
     *  before any of the console.logs within useEffect() **/
    if(!hasLoaded) {
        handleHasLoaded();
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div id={'popover-wrapped-component'}>


            {componentFromProps}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>The content of the Popover.</Typography>
            </Popover>

        </div>
    );
}

/**
 <Popover
 anchorOrigin={{
    vertical: 'center',
    horizontal: 'right',
  }}
 transformOrigin={{
    vertical: 'center',
    horizontal: 'left',
  }}
 >
 The content of the Popover.
 </Popover>

 **/