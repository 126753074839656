import React, {Component, Fragment, useEffect} from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from "axios";

// We re-write the callback into a Promise style,
// so it plays nice with React Hooks
 const getFingerprint1 = () =>
    new Promise(resolve => {
        FingerprintJS.get(components => {
            resolve(components);
        });
    });

 const getFingerprint12 = async () =>
    new Promise(resolve => {
        FingerprintJS.load(components => {
            resolve(components);
            console.log("Components: ", components);

        });
    });


export default function InitFingerprintJS() {

    FingerprintJS.load().then(fp => {
        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        fp.get().then(result => {
            // This is the visitor identifier:
            const visitorId = result.visitorId;
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! THis is visitor id: ", visitorId);
            console.log("JSON Visitor: ", result);
        });

    });

    useEffect(()=> {
        getIPAddrData( async () => {
            console.log("IP ADDR DATA: ", JSON.parse(localStorage.getItem('ip_addr_data')));
            const ip_addr_data = JSON.parse(localStorage.getItem('ip_addr_data'));
            console.log("Date String: ",);
            let current_path = window.location.pathname;
            await sendIPAxios(ip_addr_data.ip_addr, current_path, ip_addr_data.language, ip_addr_data.date);
        });
    });



    async function sendIPAxios(ip, path, language, date){
        const response =
            await axios.get("http://127.0.0.1:1337/visit",  { params: {
                    ip_addr: ip,
                    path: path,
                    language: language,
                    date: date
                }});
        console.log(response.data)
    }



    async function getIPAddrData(cb) {
        fetch('https://ifconfig.me/all.json')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                let res_data = data;
                let currentDateTime = new Date();
                let currentDateTimeStringFormatted =
                    ("00" + (currentDateTime.getMonth() + 1)).slice(-2) + "/" +
                    ("00" + currentDateTime.getDate()).slice(-2) + "/" +
                    currentDateTime.getFullYear() + " " +
                    ("00" + currentDateTime.getHours()).slice(-2) + ":" +
                    ("00" + currentDateTime.getMinutes()).slice(-2) + ":" +
                    ("00" + currentDateTime.getSeconds()).slice(-2);


                let lang = res_data.language.slice(0, 8);
                console.log("Lang: ", lang);
                res_data.language = lang;
                res_data.date = currentDateTimeStringFormatted;
                console.log("New Data: ", res_data);
                localStorage.setItem('ip_addr_data', JSON.stringify(res_data));
            }).then((data) => {cb(data)})
            .catch(error => console.error(error));
    }


    return (
        <p style={{display: 'none'}}/>
    )
}

