import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import MicIcon from '@material-ui/icons/Mic';

const styles = {
    root: {
        width: '100%',
        display: 'block', // use content(s?) for full screen width display if not within other accordions
        maxWidth: '1480px',

    },
    accordionMaster: {
        fontVariant: 'uni-case',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#3b444b',
        borderRadius: '1px',
        fontWeight: '100',
        color: '#efd280',
    },

    accordionComponent: {
        maxWidth: '1480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '2px',

    },
    accordionHeader: {
        //backgroundColor: '#515D67',
        backgroundColor: '#eee',
        borderRadius: '2px',

    },
    accordionHeaderText: {
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        marginBottom: '0%',
        letterSpacing: '0.039rem',
        //color: '#f2f3f4',

    },
    accordionBody: {
        fontSize: '0.9rem',
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',

        // marginBottom: '0%'
    },
    accordionMasterBody: {
        //backgroundColor: '#f8f8ff',
    },
    accordionIcon: {
        opacity: .8,
    },
    whitespace: {
        position: 'absolute',
    },
};
//                            {item.icon === 'mic' ? ( <MicIcon/>) : null}


class AccordionMasterParent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {classes} = this.props;
        const masters = this.props.masters ? this.props.masters : null;

        console.log('This is Master: ', masters);
        console.log('Accordion Master Parent props: ', this.props);
        console.log('Accordion Master Parent; Parent Assigned Name: ', this.props.name);
        return (
            <Container  id={'MasterAccordion'}>
                <Accordion className={classes.accordionMaster} id={'accordionMaster'}>

                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={classes.accordionHeaderText}> {this.props.name} </Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails className={classes.accordionMasterBody} id={'DetailsToContainAnotherMaster'}>
                        {this.props}
                        {masters ? (

                            masters.map((ChildThatIsParent, x) => (
                                <Accordion className={classes.accordionHeader} id={'accordionMaster'}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography className={classes.accordionHeaderText}> {ChildThatIsParent.props.name} </Typography>
                                    </AccordionSummary>
                                    <Divider/>
                                    <AccordionDetails className={classes.accordionMasterBody} id={'DetailsToContainAnotherMaster'}>
                                        {ChildThatIsParent}
                                    </AccordionDetails>
                                </Accordion>
                            ))

                        ) : null}

                    </AccordionDetails>






                </Accordion>

            </Container>


        );
    }
}


export default withStyles(styles, { withTheme: false })(AccordionMasterParent);


/**
 {this.props.masters ? (
                            {this.props.masters}
                        ): null}

 **/