import React, { Component } from 'react';

import InitFingerprintJS from "../features/IPLogging";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Menu from '../headerComponent/headerNew'
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';

import ListItem from '@material-ui/core/ListItem';
import CircleMenu from "../headerComponent/headerMobileDropDown";
import DesktopHorizontalNav from "../headerComponent/DesktopHorizontalNav";
import ThreeDimensionalLogo from "../fluidComponents/ThreeDimensionalLogo";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import SimpleAccordion from "../features/SimpleAccordion";
import ReactResponsiveCarousel from "../imageComponent/ReactResponsiveCarousel";

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    header: {

        position: 'relative',
        backgroundColor: 'black',
        width: '1920px',
        height: '1080px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    img: {
        width: '100%',
        height: '100%',
        margin:'auto',
        justifyContent: 'left',

    },
    headerImageContainer: {
        height: '1',
        width: '1'
    },
    homeH1: {
        fontWeight: '400',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        textAlign: 'center',
    },
    opaqueListHeader: {
        backgroundColor: '#22292554',
    },
    homepageBody: {
        marginTop: '0px',
    },
    infoContainer: {
        backgroundColor: 'rgba(255,255,255,.8)',
        borderRadius: '4px',
        flexGrow: 2,
        width: '100%',
        fontSize: '16px',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        boxShadow: '2px 2px 3px #aaaaaa',
        maxWidth: '1480px'
        //marginBottom: '2%',
    },
    pageHeaderTextContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1480px',
        flexGrow: 2,
        width: '100%',
        padding: 0,
        //marginTop:'4%',
        //marginBottom: '0.5rem',
    },
    pageHeaderText: {
        fontWeight: '400',
        fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        //marginBottom: '3%',
        //textAlign: 'center',
        //paddingBottom: '0.5%',
        lineHeight: '1.25em',
        position: 'relative',
        textAlign: 'center',
       // textShadow: '1px 1px 0px #aaaaaa',
    },
    infoSectionHeader: {
        paddingTop: '1%',
        paddingBottom: '0%',
        marginBottom: '0',
        fontWeight: '500',

    },

    infoSectionEndDivider: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    infoHeaderDivider: {
        marginBottom: '0.5rem',
    },
    infoSectionBody: {
        fontSize: '1rem',
        marginBottom: 0,
        //paddingTop: '0.5rem',
        //paddingBottom: '0.5rem',
    },
    infoSectionBodyFinal: {
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    insetInfoDivider: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
};

/**
 * font-family: Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
 font-weight: 400;
 line-height: 1.25em;
 position: relative;
 }
 */
/**
 *           <div className="cards">
 <a href="https://medium.com/coding-design/9c7fe9db92c7">
 <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"

 x="0px" y="0px" viewBox="0 0 200 200">
 <g>
 <path d="M0.001,0v200h200V0H0.001z M148.913,72.752h-3.869c-1.437,0-3.095,1.879-3.095,3.206v48.081
 c0,1.328,1.658,3.316,3.095,3.316h3.869v11.164h-34.708v-11.164h6.964V76.842h-0.332l-17.133,61.677H90.439L73.528,76.842h-0.332
 v50.513h7.295v11.164H51.089v-11.164h3.758c1.548,0,3.206-1.988,3.206-3.316V75.958c0-1.327-1.658-3.206-3.206-3.206h-3.758V61.146
 h36.697l12.048,44.877h0.332l12.16-44.877h36.587V72.752z"/>
 </g>
 </svg>
 <span>Read the tutorial on Medium</span>
 </a>
 <a href="https://github.com/terrymun/Fluidbox">



 <span>Check out the jQuery plugin on GitHub</span>
 </a>
 </div>
 */


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
    }


    render () {

        const preventDefault = (event) => event.preventDefault();
        const equipment_data = [
            {name: 'microphone', info: 'This is the best mic out there. Hands down.'},
            {name: 'other microphone', info: 'This is the sexiest mic out there. Doesnt work but looks cool'}

        ];
        const { classes } = this.props;

        return (

            <body className={classes.homepageBody}>




                <ListItem></ListItem>
            <div className="container-fluid" id={"home-body-container"}>

                <p>

                </p>
                <Container className={classes.pageHeaderTextContainer}>
                    <Typography className={classes.pageHeaderText} id={"pageHeaderHome"} variant="h4" component="h5">
                        Max McGill
                    </Typography>
                    <Typography className={classes.pageHeaderText} id={"pageHeaderHomeSecondLine"} variant="h4" component="h5">
                        Production Co.
                    </Typography>
                </Container>
                <ReactResponsiveCarousel/>
                <Container className={classes.infoContainer} id={'HomePageInfoContainer'}>

                    <p id={'SubsectionHeaderFirst'}> About Us </p>
                    <p className={classes.infoSectionBody} id={'infoSectionBodyStart'}>
                        Founded on the premise of delivering professional sound without the professional cost,
                        <i><b> Max McGill Production Co.</b></i> offers not only convenience of location, but convenience of pricing.
                    </p>
                    <Divider className={classes.infoSectionEndDivider}/>
                    <p id={'SubsectionHeader'}> Our Location </p>
                    <p className={classes.infoSectionBody} id={'infoSectionBody'}>
                        Our studio is located only a five-minutes drive from the Long Island town of Huntington Village.
                        If members of your recording session need to travel by train, the Greenlawn and Huntington
                        train stations are located equidistant apart— roughly one to two miles away. Together, their combined
                        train schedules permit a flexible assortment of train times. Additionally, should you need
                        transportation to and from the the station, Uber and Lyft are both extremely prevalent in our area.
                    </p>
                    <Divider className={classes.infoSectionEndDivider}/>

                    <p id={'SubsectionHeader'}> Learn More </p>
                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
                        To learn more about the services that we offer, including our on-location
                        equipment and instruments, please check out the respective
                        <Link href="/services"> Services </Link>,
                        <Link href="/equipment"> Equipment</Link>, and
                        <Link href="/instruments"> Instruments</Link> pages.
                        <br/>
                    </p>

                    <Divider className={classes.insetInfoDivider}/>
                    <p id={'SubsectionHeader'}> Have Questions? </p>
                    <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
                        If you would like to bring your own equipment/instruments,
                        or perhaps you have questions pertaining to that which we provide, feel free to
                        contact us using the <Link href="/contact-us">Contact Us</Link> page.
                    </p>

                </Container>


                <ListItem className={'final-list-item'}></ListItem>



            </div>
            <InitFingerprintJS/>
            <script src="../headerComponent/headerNew.js"></script>

            </body>
        );
    }

    scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop)


}
export default withStyles(styles, { withTheme: false })(HomePage);


/**
 *                     <Divider className={classes.insetInfoDivider} variant="inset"/>

 {window.screen.availWidth <= 738 ? (
                <CircleMenu/>
            ) : <DesktopHorizontalNav/>}
**/

/**
 * wide assortment of train times flexibility . The Greenlawn and Huntington train stations are located virtually equidistant apart from the studio. Almost equidistant apart are two train stations, Greenlawn and Huntington, which are
 Offering not only convenience of location, our customers are charged an affordable rate.
 but  that professional sound can be delivered to our customers We believe that professional sound should in
 spite of our professional have and are continually  to be pleasantly surprised


 Find out our services here
 by the comparatively low cost of recording when compared to similar studios that charge more for less.
 quality of the service offered. convenience of  Founded by an industry professional, lifelong musician and vocalist, Max McGill understands what
 it takes to produce industry-grade recordings. Located a five-minutes drive from Huntington Village, <i> Max McGill Production Co. </i> offers
 professional studio-recording services at a rate that enthusiasts can afford. <br/>
 <Divider className={classes.insetInfoDivider} variant="inset"/>
 lifelong Ensuring thaall the way into Manhattan. leave the island. Founded by an industry
 professional and lifelong musician, Max McGill
 Centerport, New York,
 Save yourself both the headache and the fees of recording at another studio. Founded by the Deep End's lead singer, Max McGill,
 heavy emphasis is put on the professionalism and quality of service that each artist receives when they record with us.
 Having themselves partook in the monotonous affair that occurs between an upcoming artist's financial constraint and the subsequent
 lack of results: we have approached our pricing model with this in mind.

 **/