import React, { Component } from 'react';



import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import InitFingerprintJS from './components/features/IPLogging'

import './Assets/css/default.min.css';
import axios from 'axios';
// import components
import Header from './components/headerComponent/header';
import Footer from './components/footerComponent/footer';
import HomePage from './components/pages/homepage';
import InstrumentPage from "./components/pages/InstrumentPage";
import EquipmentPage from "./components/pages/equipmentPage";
import EquipmentPageB from "./components/pages/equipmentPageb"
import ServicesPage from "./components/pages/ServicesPage";
import Overview from "./components/pages/Overview";
import HeaderHome from "./components/headerComponent/headerHome";
import Menu from './components/headerComponent/headerNew';
import HeaderMobile from './components/headerComponent/headerMobile'
import CircleMenu from "./components/headerComponent/headerMobileDropDown";


import DesktopHorizontalNav from "./components/headerComponent/DesktopHorizontalNav";
import ThreeDimensionalLogo from "./components/fluidComponents/ThreeDimensionalLogo";
import ImprovedHeaderHome from "./components/headerComponent/improvedHeaderHome";
const fetch = require('node-fetch');
const https = require('https');

const ip_api_url = "https://extreme-ip-lookup.com/json/";

const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
const URL = 'https://api/entries';



axios.post(PROXY_URL+ip_api_url)
    .then( i => console.log(i) )
    .catch(e => console.error( "Error occured! ", e));

const getIPWithAxios = async (cb) => {
    const response = await axios.get(ip_api_url);
    console.log("Axios IP: ", response.data);
    cb(response.data);
};


class App extends Component {


    async makeAxiosCall(cb){
      await getIPWithAxios( (data) => {
            console.log("Within getipwithaxios: ", data);
            cb(data)
        });
    }

    componentDidMount() {
        console.log('Avail Screen Width: ', window.screen.availWidth);
        console.log(window.screen.availWidth <= 738);
        console.log("app mounted buitch");
       this.makeAxiosCall( async (d) => {
           console.log("Withing component did mount: ", d)
       });
        const getUserGeolocationDetails = () => {
            fetch(
                "https://geolocation-db.com/json/09ba3820-0f88-11eb-9ba6-e1dd7dece2b8"
            )
                .then(response => response.json())
                .then(data => console.log("Youtube demo: ", data));
        };
    }

    render() {


      return (

          <Router id="fluidbox-overlay">
                <div id={"App"}>

                    {window.location.pathname === '/NOTPRODREADY' && (localStorage.getItem('headImageShown') !== 'true') ? (
                        <ImprovedHeaderHome/>
                    ): null}

                    {window.screen.availWidth <= 738 ? (
                        <CircleMenu/>
                    ) : <DesktopHorizontalNav/>}

                    {window.screen.availWidth >= 738 && window.location.pathname === '/NOPEEEEE' ? (
                        <ThreeDimensionalLogo/>
                    ) : null}

                          <Route exact path='/' component={HomePage}/>
                          <Route exact path='/instruments' component={InstrumentPage}/>
                          <Route exact path='/equipment' component={EquipmentPage}/>
                          <Route exact path='/overview' component={Overview}/>
                          <Route exact path='/services' component={ServicesPage}/>
                          <Route path='/contact-us' component={() => {
                                window.location.href = 'https://www.instagram.com/thedenproductions/';
                                return null;
                            }}/>

                        <Route path='/happelsolutions' component={() => {
                            window.location.href = 'https://www.instagram.com/happelsolutions/';
                            return null;
                        }}/>
                             <Route exact path='/equipmentb' component={EquipmentPageB}/>

                    <Footer/>
                </div>
          </Router>
      );

    }


}

export default App;
//