

import React, { Component, createClass } from 'react';
import ReactDOM from 'react-dom';

// Already included! Does not need to be included here
//import './Assets/css/default.min.css';

import { Link } from "react-router-dom";

import logo from "./../../favicon.ico";

import ClearIcon from '@material-ui/icons/Clear';
import Icon from '@material-ui/core/Icon';

export default class Menu extends Component<props> {

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    toggleMenu = () => {
        this.setState(state => ({
            open: !this.state.open
        }));
    };

    componentDidMount = () => {
        this.setState(state => ({
            open: false
        }));
    };

    render () {

        const linksArray = [
            { name: "home", url: "#" },
            { name: "log in", url: "#" },
            { name: "photos", url: "#" },
            { name: "contact", url: "#" },
            { name: "download", url: "#" }
        ];
        const socialArray = [
            {
                icon: "fa fa-github-square",
                url: "https://github.com/matthewvincent"
            },{
                icon: "fa fa-instagram",
                url: "https://www.instagram.com/middlestates/"
            },{
                icon: "fa fa-tumblr-square",
                url: "http://matthewvincentphotography.com/"
            }
        ];

        return (
            <header id={'new-header'}>
                <div className="container-fluid" id={'new-nav-container'}>

                    <Panel
                        open={this.state.open}
                        links={linksArray}
                        socialLinks={socialArray}
                    />
                    <Button
                        toggle={this.toggleMenu}
                        open={this.state.open}
                    />
                </div>
            </header>
        );
    }
}

class Button extends Component {
    render () {
        return (
            <button
                className={this.props.open
                    ? "menu-toggle close-button"
                    : "menu-toggle "}
                onClick={this.props.toggle}
            > <Icon className="fa fa-plus"><ClearIcon/></Icon>
            </button>
        );
    }
}

class Panel extends Component {
    render() {
        return (
            <div
                className={this.props.open
                    ? "menu-wrapper menu-open"
                    : "menu-wrapper"}
            >
                <Links
                    links={this.props.links}
                    open={this.props.open}
                />
                <Social
                    socialLinks={this.props.socialLinks}
                    open={this.props.open}
                />
            </div>
        );
    }
}

class Links extends Component {
    render() {
        const linkList = this.props.links.map((link) => (
            <li className="link">
                <a href={link.url}>{link.name}</a>
            </li>
        ));

        return (
            <div
                className={this.props.open
                    ? "links-wrapper"
                    : "links-wrapper links-wrapper-closed"}
            > <ul className="link-list">
                {linkList}
            </ul>
            </div>
        );
    }
}

class Social extends Component {
    render() {
        const socialLinks = this.props.socialLinks.map((link) => (
            <a href={link.url}>
                <i className= {link.icon} />
            </a>
        ));

        return (
            <div
                className={this.props.open
                    ? "social-wrapper social-open"
                    : "social-wrapper social-closed"}
            > {socialLinks}
            </div>
        );
    }
}


//ReactDOM.render(<Menu/>, document.getElementById('menu'));
//export default Menu;

/**

 <div className="logo">
 <img src={logo} alt="Logo"/>
 </div>

 **/