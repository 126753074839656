

import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Modal, ModalGateway } from 'react-images';
import Grow from '@material-ui/core/Grow';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import ScrollDemo from "../features/ScrollDemo";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';
import headerImage from '../../../src/studio.jpg';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Paper} from '@material-ui/core'
import Carousel from 'react-material-ui-carousel';
import MobileMicBanner from '../../Assets/photos/MicrophoneMobileBanner.png';
import MicBanner from '../../Assets/photos/MicrophoneBanner.png';
import GuitarBanner from '../../Assets/photos/ExtendedGuitarBanner.png';
import MobileRender from '../../Assets/photos/RenderedMicMobileSquare.png'
import GuitarMobileRender from '../../Assets/photos/GuitarMobileRender.png'
import {Mic} from "@material-ui/icons";






function Banner(props) {
    const { classes } = props;
    const imageCompMappings = {
        'Mic': '../../Assets/photos/MicrophoneBanner.png',
        'Guitar': '../../Assets/photos/ExtendedGuitarBanner.png',
    };

    console.log('BANNER Props: ', props );
    //console.log('imageCompMappin[props.imageComp: ', imageCompMappings[props.item.props.imageComp].toString() );
    return (
        <Paper className={classes.PaperForCarousel}>
            <Container className={classes.ImageContainer}>
                <img  id={'HomePageCarouselImage'}
                    alt={'x'}
                    src={props.item.props.imageComp}

                />

            </Container>
        </Paper>
        )

}
const styles = {
    CarouselContainer: {
        //display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '100%',
        maxWidth: 'none',
        maxHeight: '700px',
        marginBottom: '1rem',
    },
    CarouselComponent: {
        maxHeight: '500px',
        //display: 'contents',

    },
    GalleryImage: {
        width: '10%',
        height: '10%',
        objectFit: 'cover',
    },
    ImageContainer: {
        minWidth: '300px',
        maxWidth: '1480px',
        display: 'flex',
        margin: 'auto',
        padding: '0'
    },

    PaperSummaryContainer: {
        maxWidth: 'none',
        display: 'flex',
        margin: 'auto'

    },
    PaperForCarousel: {
    },
    PaperHeadText: {

    },
    PaperHeadBody: {

    },
};

function Item(props) {
    console.log('ITEM Props: ', props);
    const { classes } = props;
    return (
        <Paper className={classes.PaperForCarousel}>
            <Container className={classes.ImageContainer}>


            <img className={props.classes.GalleryImage}
                 src={props.item.image_url}
                 alt={'x'}
                 style={{
                     objectFit: 'cover',
                     transform: 'initial',
                     visibility: 'visible',
                     opacity: 1,
                 }}
            />
            </Container>


        </Paper>
    )
}

/**
 *
 *  <Container className={classes.PaperSummaryContainer}>
 <h2 className={classes.PaperHeadText}>{props.item.name}</h2>
 <p className={classes.PaperTextBody}>{props.item.description}</p>
 </Container>
 * <h2>{props.item.name}</h2>
 <p>{props.item.description}</p>

 <Button className="CheckButton">
 Check it out!
 </Button>
 */
// Local Storage -- homeHeaderPreviouslyShown
class ReactResponsiveCarousel extends Component{

    constructor(props) {
        super(props);
        this.state = {hasMounted: false};
        this.myRef = React.createRef();
        this.local_images = [{src: '../../../photos/examples/a.PNG'}, {src: '../../../photos/examples/b.PNG'},
            {src: '../../../photos/examples/c.PNG'}, {src: '../../../photos/examples/d.PNG'},
            {src: '../../../photos/examples/e.PNG'}, {src: '../../../photos/examples/f.PNG'},
            {src: '../../../photos/examples/g.PNG'}, {src: '../../../photos/examples/h.PNG'},
            {src: '../../../photos/examples/i.PNG'}, {src: '../../../photos/examples/j.PNG'},
            {src: '../../../photos/examples/k.PNG'}, {src: '../../../photos/examples/l.PNG'},
            {src: '../../../photos/examples/m.PNG'}, {src: '../../../photos/examples/n.PNG'},
            {src: '../../../photos/examples/o.PNG'}];

    }

    componentWillUnmount() {
        console.log('Mounted');
    }








    componentDidMount() {
        console.log('Component Carousel Mounted');
    }

    render () {
        console.log('Rendering...................................');
        var items_two = [
            {
                name: "Random Name #1",
                description: "Probably the most random thing you have ever seen!",
            },
            {
                name: "Random Name #1",
                description: "Probably the most random thing you have ever seen!",
            }
        ];


        var items = [
            {
                name: "Random Name #1",
                description: "Probably the most random thing you have ever seen!",
                image_url: 'https://live.staticflickr.com/65535/50615560398_a3b952e098_o.png',
            },

            {
                name: 'Microphone Banner',
                description: 'Mic Banner',
                //image_url: 'https://live.staticflickr.com/65535/50617851581_53b93ae50c_k.jpg',
                image_url: '../../../photos/banners/MicrophoneBanner.png',
            }

        ];

        const { classes } = this.props;
        const p = {interval: 6000};
        return (
            /**
            <Container className={classes.CarouselContainer}>
                <Carousel className={classes.CarouselComponent}>
                    {
                        items.map( (item, i) => <Item classes={classes} key={i} item={item} /> )
                    }
                </Carousel>
            </Container>**/
            <Container className={classes.CarouselContainer}>

                    {window.screen.availWidth > 738 ? (

                        <Carousel className={classes.CarouselComponent} {...p}>
                            <Banner classes = {classes} key={1} item={<Banner classes={classes} key={1} {...p} imageComp={MicBanner}/>} />
                            <Banner classes={classes} key={2} item={<Banner classes={classes} key={2} {...p} imageComp={GuitarBanner}/>} />
                        </Carousel>

                        ) :

                        <Carousel className={classes.CarouselComponent} {...p}>
                            <Banner classes = {classes} key={1} item={<Banner classes={classes} key={1} {...p} imageComp={MobileRender}/>} />
                            <Banner classes={classes} key={2} item={<Banner classes={classes} key={2} {...p} imageComp={GuitarMobileRender}/>} />
                        </Carousel>

                    }

            </Container>
        );
    }


    /**
     *


     *
     *   {
                        items_two.map( (item, i) => <Banner classes={classes} key={i} item={item} /> )
                    }
     *
     *
     *
     * {
                name: "Random Name #2",
                description: "Hello World!",
                image_url: 'https://live.staticflickr.com/65535/50556723878_a461097f7a_b.jpg',
            }
     */

}

export default withStyles(styles, { withTheme: false })(ReactResponsiveCarousel);
//xport default withStyles(styles, { withTheme: false })(HeaderHome);


/**
Back-up before attempting to resolve image gallery issue on iPhone

 <Carousel className={classes.CarouselComponent} {...p}>
 <Banner classes = {classes} key={1} item={<Banner classes={classes} key={1} {...p} imageComp={MicBanner}/>} />
 <Banner classes={classes} key={2} item={<Banner classes={classes} key={2} {...p} imageComp={GuitarBanner}/>} />
 </Carousel>

 ) :

 <Carousel className={classes.CarouselComponent} {...p}>
 <Banner classes = {classes} key={1} item={<Banner classes={classes} key={1} {...p} imageComp={MobileRender}/>} />
 <Banner classes={classes} key={2} item={<Banner classes={classes} key={2} {...p} imageComp={GuitarMobileRender}/>} />
 </Carousel>

 }

 **/