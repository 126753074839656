import React, { Component } from 'react';
// Already included! Does not need to be included here
//import './Assets/css/default.min.css';

import { Link } from "react-router-dom";

import logo from "./../../favicon.ico";


class Header extends Component {
    render () {


        return (

            <header>

                <div className="logo">
                    <img src={logo} alt="Logo"/>
                </div>

                <nav>
                    <ul>
                        <li className="first">
                            <Link to="/" className="headerLink">Home</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to="/" className="headerLink">Services</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="last">
                            <Link to="/instruments" className="headerLink">Instruments</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to="/equipment" className="headerLink">Equipment</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="last">
                            <Link to="#" className="headerLink">Contact</Link>
                        </li>
                    </ul>

                </nav>

            </header>

        );
    }
}


export default Header;
